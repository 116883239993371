import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Pannello } from '../../Models/Pannello';
import Dictionary from '../../Config/dictionary';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Sala } from '../../Models/Sala';
import SalaService from '../../Services/SalaService';
import RisultatoOperazione from '../../Models/RisultatoOperazione';
import { action_number, action_pannello, action_Risultati, action_signage, action_string, ReducerAction } from '../../Services/Reducers/ReducerAction';
import PannelloService from '../../Services/PannelloService';
import { useEffect } from 'react';
import { useSession } from '../../Context/SessionContext';
import { fontSize } from '@mui/system';
import { QueryModel } from '../../Services/Utils/QueryModel';
import LoadingBar from '../../Layouts/LoadingBar';
import "./FormDialogSignage.css";
import { SignageBook } from '../../Models/SignageBook';
import SignageService from '../../Services/SignageService';
import axios from 'axios';



type Props = {
  _open: boolean;
  mostraModal: Function;
  signage: SignageBook;
  confirmFunction: Function;
  confirmUpdate: Function;
  confirmCreate: Function;
  errorFunction: Function;
  notvalidFunction: Function;
  idSala: number;
};

class statoForm {
  signageForm: SignageBook = new SignageBook();

  formvalid: boolean = false;

  filevalid: boolean = true;

  file_blur: boolean = false;


  risultato: RisultatoOperazione[] = [];

  constructor(signage: SignageBook) {
    this.signageForm = { ...signage };
  }
}

enum inputId {
  File = 'inputFile',
}

enum azioni {
  RESET = 'reset',
  SUBMIT = 'submit',
  FORM_CHANGE = 'update',
  CHANGE_FILE = 'changeFile',
  BLUR_FILE = 'blurFile',
}

function reducer(state: statoForm, action: ReducerAction) {
  let stato = { ...state };

  switch (action.type) {

    case azioni.RESET: {
      stato = new statoForm((action as action_signage).value);
      return stato;
    }

    case azioni.SUBMIT: {
      stato.risultato = (action as action_Risultati).value;
      stato = new statoForm(new SignageBook());
      return stato;
    }

    case azioni.FORM_CHANGE: {
      stato = raccogliInput(stato);
      stato = convalidaForm(stato);
      return stato;
    }

    case azioni.BLUR_FILE: {
      stato.file_blur = true;
      stato = convalidaForm(stato);
      return stato
    }


    default: return state;
  }
}

const FormDialogSignage: React.FC<Props> = ({ _open, mostraModal, signage, confirmFunction, errorFunction, notvalidFunction, confirmUpdate, confirmCreate, idSala }) => {

  const handleClose = () => { mostraModal(false); confirmFunction();  setLoading(false); setFileSelected(undefined); setNomeFile("")};
  const handleAbort = (e: any) => {  mostraModal(false); handleResetForm(e); setFileSelected(undefined); setNomeFile("") };
  const handleNotValidInsert = () => { notvalidFunction(); checkNotValidInsertFields(); }
  const handleNotValidUpdate = () => { notvalidFunction(); checkNotValidUpdateFields(); }
  const handleError = () => { errorFunction(); mostraModal(false) };
  const [state, dispatch] = React.useReducer(reducer, new statoForm(signage));
  const [notValidFile, setNotValidFile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fileSelected, setFileSelected] = React.useState<File>();
  const [nomeFile, setNomeFile] = React.useState('');
  const [imageUrl, setImageUrl] = React.useState<string>();


  const { session, setSession } = useSession();

  const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => { resolve(reader.result?.toString() || ''); }
      reader.onerror = error => reject(error);
    })
  }


  useEffect(() => {
    if (fileSelected) {
      setImageUrl(URL.createObjectURL(fileSelected));
    }
  }, [fileSelected]);

  useEffect(() => {
    dispatch({ type: azioni.RESET, value: signage })
  }, [signage]);

  function checkNotValidInsertFields() {

    if (!state.signageForm.file) setNotValidFile(true);
    handle_blur(azioni.BLUR_FILE)
  }

  function checkNotValidUpdateFields() {
    if (!signage.file) setNotValidFile(true);
  }



  async function handle_submit(e: any) {

    let lunghezzaFormatoImg = ((fileSelected?.type as string).length) - 6;

    if (fileSelected) {

      let base64 = file2Base64(fileSelected);
      setLoading(true);

      let risultati: RisultatoOperazione[] = [];

      let signage = new SignageBook();
      signage.file = (await base64).slice((19 + lunghezzaFormatoImg!));
      signage.fkSala = idSala;
      signage.nomeFile = fileSelected.name;

      let success: boolean = await SignageService.AddSignage(signage);
      console.log(success)
      let msg: string = (success) ? Dictionary.FormSignage.log_add_success : Dictionary.FormSignage.log_add_error;

      risultati.push(new RisultatoOperazione(success, msg));

      dispatch({ type: azioni.SUBMIT, value: risultati });
      (!success) ? handleError() : handleClose(); confirmCreate();
      //confirmFunction();
    } else {
      handleNotValidInsert();
    }

  }

  function handle_change(e: any) {

    dispatch({ type: azioni.CHANGE_FILE, value: e.target.value });
  };

  function handle_blur(campo: azioni) {
    dispatch({ type: campo });
  }

  function handleResetForm(e: any) {
    dispatch({ type: azioni.RESET, value: e.target.value })
  }

  function handleFile(e: any) {
    const fileList = e.target.files;
    console.log(fileList)
    if (!fileList) return;

    setFileSelected(fileList[0]);
    setNomeFile(fileList[0].name);

  }

  return (
    <div>
      <Dialog style={{minWidth:'450px'}}  open={_open} onClose={(e) => handleAbort(e)}>
        <DialogTitle>{Dictionary.FormSignage.addForm_title}</DialogTitle>
        <LoadingBar show={loading} />
        <DialogContent className={fileSelected?'signageDialogContent':""} dividers>
            <label htmlFor="upload-photo">
              <input style={{ display: 'none' }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={(e) => handleFile(e)} />

              {imageUrl && fileSelected && (
                
                  <img className='previewImg' src={imageUrl} alt={fileSelected.name}  />
                
              )} 
              <p className='nomeFile'>{nomeFile}</p>
              
              <Button sx={!fileSelected? {marginLeft: "1.8rem", marginBottom: "1rem"}: {}} className='buttonSelectImg' color="success" variant="contained" component="span">
                Seleziona file
              </Button>

             

            </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleAbort(e)} color="success">Annulla</Button>

          <Button variant="contained" color="success"
            onClick={(e) => { handle_submit(e) }} >
            Salva
          </Button>



        </DialogActions>
      </Dialog>
    </div>
  );


}
export default FormDialogSignage;

function raccogliInput(stato: statoForm) {
  /*   stato.signageForm.file = document.getElementById(inputId.File)?.value;
   */
  return stato;
}

function convalidaForm(stato: statoForm) {

  if (stato.signageForm.file) {
    stato.formvalid = true;
  } else stato.formvalid = false;

  return stato;
}


