const SPEC_CHAR_REGEX = new RegExp(/[ `!@#$£%^&*()_+\-=\]{};':"\\|,.<>?~]/);
const NUM_CHAR_REGEX = new RegExp(/\d/);

export default class Password {

    private value: string = '';
    private valid: boolean = false;

    public Value = (): string => { return this.value; }
    public Valid = (): boolean => { return this.valid; }

    constructor(pw: string) {
        this.set(pw);
    }

    public set = (pw: string): void => {
        this.value = pw;
        this.valid = this.convalida(pw);
    }

    private convalida = (pw: string): boolean => {

        if (pw.length >= 8 // &&
            // pw.toLowerCase() !== pw &&
            // pw.toUpperCase() !== pw &&
            // NUM_CHAR_REGEX.test(pw) &&
            // SPEC_CHAR_REGEX.test(pw)
        ) return true;
        else return false;
    }
}