import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

export interface State {
  open: boolean;
  vertical: string;
  horizontal: string;
}

type Props = {
    _open: boolean;
    _vertical: "top"|"bottom";
    _horizontal: "left"|"center"|"right";
    _message: string;
   mostraModal: Function;
   _autoClose: number;
   _severity: "success"|"error";
}; 

const GenericToast: React.FC <Props>  = ({_open, _vertical, _horizontal, _message, mostraModal, _autoClose, _severity}) =>  {
  const [state, setState] = React.useState<State>({
    open: _open,
    vertical: _vertical,
    horizontal: _horizontal,
  });
  const { vertical, horizontal } = state;
  const handleClose = () => { mostraModal(false) };

  return (
    <div>
      
      <Snackbar
        anchorOrigin={{ vertical: _vertical , horizontal: _horizontal }}
        open={_open}
        onClose={handleClose}
        message={_message}
        key={vertical + horizontal}
        autoHideDuration={_autoClose}
        
      ><Alert onClose={handleClose} severity={_severity} variant='filled' sx={{ width: '100%' }}>
      {_message}
    </Alert>
    </Snackbar>
    </div>
  );
}
export default GenericToast;
