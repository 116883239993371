export default class Dictionary {

    static readonly Routes = {
        landingPage_route: "/",
        dashboard_route: "/dashboard",
        settings_route: "/impostazioni",
        utenti_route: "/utenti",
        profilo: "/profilo",
        sale: "/sale",
        pannelli: '/pannelli',
        signage: '/signage',
        card:'/card'
    }

    static readonly Login = {
        loginForm_title: "EFFETTUA IL LOGIN",
        loginForm_subtitle: "Inserisci il nome utente e la password",
        loginSide_title: "BOOKING SYSTEM LITE",
        loginSide_subtitle: "Gestisci le sale e i pannelli.",
        loginSide_lastTitle: "Organizza le tue riunioni in completa autonomia."
    }

    static readonly Dashboard = {
        dashboard_sectionTitle: "BSLite - Dashboard",
        last_day_box_title: "PRENOTAZIONI ULTIME 24 ORE",
        last_week_box_title: "PRENOTAZIONI ULTIMI 7 GIORNI",
        last_month_box_title: "PRENOTAZIONI ULTIMI 30 GIORNI",
        deleted_box_title: "PRENOTAZIONI ANNULLATE",
        all_prenotation_by_hour: "Prenotazioni totali per fascia oraria",
        all_prenotation_by_sala: "Prenotazioni totali per sala",
        all_prenotation_by_hourAndSala: "Prenotazioni totali per fascia oraria in base alla sala"
    }

    static readonly GestioneSale = {
        gestioneSale_sectionTitle: "Sale",
        update_confirm_alert: "Sala modificata correttamente.",
        delete_confirm_alert: "Sala eliminata correttamente.",
        create_confirm_alert: "Sala aggiunta correttamente."
    }
    static readonly GestionePannelli = {
        gestionePannelli_sectionTitle: "Pannelli",
        update_confirm_alert: "Pannello modificato correttamente.",
        delete_confirm_alert: "Pannello eliminato correttamente.",
        create_confirm_alert: "Pannello aggiunto correttamente."

    }

    static readonly GestioneUtenti = {
        gestioneUtenti_sectionTitle: "Utenti"
        , crea_utente: 'CREA NUOVO UTENTE'
        , modif_utente: 'MODIFICA UTENTE'
        , confermaElimina: "Conferma eliminazione utente: "
        , utente_eliminato: "Utente eliminato con successo"
        , utente_modificato: "Utente modificato con successo"
        , utente_creato: "Utente creato con successo"
        , utente_non_creato: "Creazione utente non riuscita"
        , utente_non_modificato: "Modifica utente non riuscita"
        , utente_non_eliminato: "Eliminazione utente non riuscita"
        , paginazione: "Utenti per pagina"
        , NameNotAvailable: 'Username non disponibile'
        , non_elim_ultimo_admin : 'Impossibile rimuovere ultimo admin'
    }

    static readonly GestioneCard = {
        gestioneCard_sectionTitle: "Card"
        , crea_card: 'CREA NUOVA CARD'
        , modif_card: 'MODIFICA CARD'
        , confermaElimina: "Conferma eliminazione card: "
        , card_eliminato: "Card eliminata con successo"
        , card_modificato: "Card modificata con successo"
        , card_creato: "Card creata con successo"
        , card_non_creato: "Creazione card non riuscita"
        , card_non_modificato: "Modifica card non riuscita"
        , card_non_eliminato: "Eliminazione card non riuscita"
        , paginazione: "Card per pagina"
        , IdentifierNotAvailable: 'Codice non disponibile'
    }

    static readonly GestioneSignage = {
        gestioneSignage_sectionTitle: "Signage Booking - ",
        update_confirm_alert: "Immagine modificata correttamente.",
        delete_confirm_alert: "Immagine eliminata correttamente.",
        create_confirm_alert: "Immagine aggiunta correttamente."
    }

    static readonly Settings = {
        settings_sectionTitle: "Impostazioni Generali",
        warning_change_configuration: 'ATTENZIONE',
        explanation_change_warning: 'Confermando applicherai le impostazioni a TUTTE le sale.',
        change_confirm_alert: "Impostazioni cambiate correttamente."
    }

    static readonly AccountMenu = {
        accountMenu_tooltipTitle: "Impostazioni profilo",
        accountMenu_option1: "Profilo",
        accountMenu_option2: "Impostazioni Utente",
        accountMenu_option3: "Logout"
    }

    static readonly SideBar = {
        sidebar_width: 250,
        option_list: ['Sale', 'Pannelli', 'Utenti', 'Impostazioni'],
    }

    static readonly Pagination = {
        pagination_label: "elementi per Pagina"
    }

    static readonly GridSale = {
        pagination_title: "Sale per pagina:",
        warning_delete_message: "Sei sicuro di voler eliminare la sala?",
        explanation_delete_warning: "Confermando eliminerai la Sala:  ",
    }

    static readonly SearchBar = {
        create_confirm_alert: " Aggiunta effettuata correttamente."
    }

    static readonly GridPannelli = {
        pagination_title: "Pannelli per pagina:",
        warning_delete_message: "Sei sicuro di voler eliminare il pannello?",
        explanation_delete_warning: "Confermando eliminerai il Pannello:  ",
    }

    static readonly GridSignage = {
        pagination_title: "Immagini per pagina:",
        warning_delete_message: "Sei sicuro di voler eliminare l'immagine?",
        explanation_delete_warning: "Confermando eliminerai l'immagine:  ",
    }

    static readonly FormSala = {
        addForm_title: 'AGGIUNGI SALA',
        updateForm_title: 'MODIFICA SALA',
        log_add_success: 'Sala aggiunta con successo',
        log_add_error:'Sala non aggiunta',
        log_update_success: 'Sala modificata con successo',
        log_update_error: 'Sala non modificata'
    }

    static readonly FormPannello = {
        addForm_title: 'AGGIUNGI PANNELLO',
        updateForm_title: 'MODIFICA PANNELLO',
        log_add_success: 'Pannello aggiunto con successo',
        log_add_error:'Pannello non aggiunto',
        log_update_success: 'Pannello modificato con successo',
        log_update_error: 'Pannello non modificato'
    }

    static readonly FormSignage = {
        addForm_title: 'AGGIUNGI IMMAGINE',
        updateForm_title: 'MODIFICA IMMAGINE',
        log_add_success: 'Immagine aggiunta con successo',
        log_add_error:'Immagine non aggiunta',
        log_update_success: 'Immagine modificato con successo',
        log_update_error: 'Immagine non modificato'
    }

    static readonly FormCard = {
        email_not_valid: "formato email non valido",
        submit_button: "Salva",
        abort_button: "Annulla"
    }

    static readonly Utility = {
        lorem: "Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper" +
            "eget nulla facilisi etiam dignissim diam.Pulvinar elementum integer enim" +
            "neque volutpat ac tincidunt.Ornare suspendisse sed nisi lacus sed viverra" +
            "tellus.Purus sit amet volutpat consequat mauris.Elementum eu facilisis" +
            "sed odio morbi.Euismod lacinia at quis risus sed vulputate odio.Morbi" +
            "tincidunt ornare massa eget egestas purus viverra accumsan in.In hendrerit" +
            "gravida rutrum quisque non tellus orci ac.Pellentesque nec nam aliquam sem" +
            "et tortor.Habitant morbi tristique senectus et.Adipiscing elit duis" +
            "tristique sollicitudin nibh sit.Ornare aenean euismod elementum nisi quis" +
            "eleifend.Commodo viverra maecenas accumsan lacus vel facilisis.Nulla" +
            "posuere sollicitudin aliquam ultrices sagittis orci a.",
        lorem_short: "Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper" +
            "eget nulla facilisi etiam dignissim diam.Pulvinar elementum integer enim" +
            "neque volutpat ac tincidunt.Ornare suspendisse sed nisi lacus sed viverra",
        lorem_large: "Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper" +
            "eget nulla facilisi etiam dignissim diam.Pulvinar elementum integer enim" +
            "neque volutpat ac tincidunt.Ornare suspendisse sed nisi lacus sed viverra" +
            "tellus.Purus sit amet volutpat consequat mauris.Elementum eu facilisis" +
            "sed odio morbi.Euismod lacinia at quis risus sed vulputate odio.Morbi" +
            "tincidunt ornare massa eget egestas purus viverra accumsan in.In hendrerit" +
            "gravida rutrum quisque non tellus orci ac.Pellentesque nec nam aliquam sem" +
            "et tortor.Habitant morbi tristique senectus et.Adipiscing elit duis" +
            "tristique sollicitudin nibh sit.Ornare aenean euismod elementum nisi quis" +
            "eleifend.Commodo viverra maecenas accumsan lacus vel facilisis.Nulla" +
            "posuere sollicitudin aliquam ultrices sagittis orci a " +
            "Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper" +
            "eget nulla facilisi etiam dignissim diam.Pulvinar elementum integer enim" +
            "neque volutpat ac tincidunt.Ornare suspendisse sed nisi lacus sed viverra" +
            "tellus.Purus sit amet volutpat consequat mauris.Elementum eu facilisis" +
            "sed odio morbi.Euismod lacinia at quis risus sed vulputate odio.Morbi" +
            "tincidunt ornare massa eget egestas purus viverra accumsan in.In hendrerit" +
            "gravida rutrum quisque non tellus orci ac.Pellentesque nec nam aliquam sem" +
            "et tortor.Habitant morbi tristique senectus et.Adipiscing elit duis" +
            "tristique sollicitudin nibh sit.Ornare aenean euismod elementum nisi quis" +
            "eleifend.Commodo viverra maecenas accumsan lacus vel facilisis.Nulla" +
            "posuere sollicitudin aliquam ultrices sagittis orci a" +
            "gravida rutrum quisque non tellus orci ac.Pellentesque nec nam aliquam sem" +
            "et tortor.Habitant morbi tristique senectus et.Adipiscing elit duis" +
            "tristique sollicitudin nibh sit.Ornare aenean euismod elementum nisi quis" +
            "eleifend.Commodo viverra maecenas accumsan lacus vel facilisis.Nulla" +
            "posuere sollicitudin aliquam ultrices sagittis orci a" +
            "gravida rutrum quisque non tellus orci ac.Pellentesque nec nam aliquam sem" +
            "et tortor.Habitant morbi tristique senectus et.Adipiscing elit duis" +
            "tristique sollicitudin nibh sit.Ornare aenean euismod elementum nisi quis" +
            "eleifend.Commodo viverra maecenas accumsan lacus vel facilisis.Nulla" +
            "posuere sollicitudin aliquam ultrices sagittis orci a."
    }
}