
import './App.css';
import Login from "./Pages/Login/Login";
import './App.css';
import { BrowserRouter as Router, HashRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from './Pages/Dashboard/Dashboard';
import Settings from './Pages/Settings/Settings';
import { SessionContext } from './Context/SessionContext';
import { Component, useEffect, useState } from 'react';
import { AppSession, SessionManager } from './Services/SessionManager';
import NotFound from './Pages/Navigazione/NotFound';
import GestioneUtenti from './Pages/GestioneUtenti/GestioneUtenti';
import ProfiloUtente from './Pages/ProfiloUtente/ProfiloUtente';
import Dictionary from './Config/dictionary';
import './App.css';
import GestioneSale from './Pages/GestioneSale/GestioneSale';
import GestionePannelli from './Pages/GestionePannelli/GestionePannelli';
import GestioneSignage from './Pages/GestioneSignage/GestioneSignage';
import GestioneCard from './Pages/GestioneCard/GestioneCard';


function App() {
  const [session, setSession] = useState(new AppSession());
  const [content, setContent] = useState(<></>);
  const [mounted,setMounted] = useState(false);
  const navigate = useNavigate();
  const routes = Dictionary.Routes;

  const setRedirect = () => {
    let content = (!SessionManager.isValid(session)) ? <Login /> : <NotFound />
    return content;
  }

  useEffect(() => {
    if (!SessionManager.isValid(session))
      setSession(SessionManager.GetSessionFromStorage());
      setMounted(true);
  }, []);

  useEffect(() => { //redirection su login quando sessione scade
    if (mounted && !SessionManager.isValid(session)) {
      navigate(routes.landingPage_route, { replace: false });
    }
    setContent(setRedirect());
  }, [session]);

  useEffect(() => { //monitoraggio validità di sessione
    const interval = setInterval(() => {
      setSession(SessionManager.GetSessionFromStorage());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (

    <div className="App">
      <SessionContext.Provider value={{ session, setSession }}>

        <Routes>
          <Route path="/" element={<Login />} />

          {SessionManager.isValid(session) && /*route permesse a utenti loggati*/ <>
            <Route path={routes.settings_route} element={<Settings />} />
            <Route path={routes.pannelli} element={<GestionePannelli />} />
            <Route path={routes.sale} element={<GestioneSale />} />
            <Route path={routes.utenti_route} element={<GestioneUtenti />} />
            <Route path={routes.card} element={<GestioneCard />} />
            <Route path={routes.dashboard_route} element={<Dashboard />} />
            <Route path={routes.profilo} element={<ProfiloUtente />} />
            <Route path={routes.signage} element={<GestioneSignage />} />

          </>
          }

          <Route path="/" element={<Login />} />
          <Route path="*" element={content} />
        </Routes>

      </SessionContext.Provider>
    </div >

  )
}

export default App;