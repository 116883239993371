import { Expressions } from "../Services/Enums/Expressions";
import { UserRoles } from "../Services/Enums/UserRolesEnum";

export class Utente {

    id: string = '';
    userName: string = '';
    role: string = '';
    isLastAdmin?: boolean;

}