import { QueryModel } from "../Utils/QueryModel"
import { action_number, ReducerAction } from "./ReducerAction"


export enum queryActions {
    Search = 'search',
    SortBy = 'sortby',
    NextPage = 'nextpage',
    PrevPage = 'prevpage',
    PageSize = 'pagesize',
    GoToPage = 'gotopage',
    Reset = 'reset'
}

export type act_searchBy = {
    type: string,
    value: { searchBy: string, searchString: string }
}
export type act_sortBy = {
    type: string,
    value: { sortBy: string, direction: 'asc' | 'desc' }
}

export function queryReducer(state: QueryModel, action: ReducerAction | act_searchBy | act_sortBy): QueryModel {
    let query = { ...state };

    switch (action.type) {
        case queryActions.Search: {
            let { searchBy, searchString } = (action as act_searchBy).value;
            QueryModel.setSearch(query, searchString, searchBy);
            QueryModel.setPage(query,query.pageSize,1);
            break;
        }
        case queryActions.SortBy: {
            let { sortBy, direction } = (action as act_sortBy).value;
            QueryModel.setSort(query, sortBy, direction);
            break;
        }
        case queryActions.NextPage: {
            QueryModel.nextPage(query);
            break;
        }
        case queryActions.PrevPage: {
            QueryModel.prevPage(query);
            break;
        }
        case queryActions.PageSize: {
            QueryModel.setPage(query, (action as action_number).value, query.pageNumber);
            break;
        }
        case queryActions.GoToPage: {
            QueryModel.setPage(query, query.pageSize, (action as action_number).value);
            break;
        }
        case queryActions.Reset: {
            query = new QueryModel();
            break;
        }
    }
    return query;
}