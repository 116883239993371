import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Pannello } from '../../Models/Pannello';
import Dictionary from '../../Config/dictionary';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Sala } from '../../Models/Sala';
import SalaService from '../../Services/SalaService';
import RisultatoOperazione from '../../Models/RisultatoOperazione';
import { action_number, action_pannello, action_Risultati, action_string, ReducerAction } from '../../Services/Reducers/ReducerAction';
import PannelloService from '../../Services/PannelloService';
import { useEffect } from 'react';
import { useSession } from '../../Context/SessionContext';
import { fontSize } from '@mui/system';
import { QueryModel } from '../../Services/Utils/QueryModel';
import LoadingBar from '../../Layouts/LoadingBar';
import "./FormDialogPannello.css";



type Props = {
  _open: boolean;
  mostraModal: Function;
  pannello: Pannello;
  nomeSala: string;
  confirmFunction: Function;
  confirmUpdate: Function;
  confirmCreate: Function;
  errorFunction: Function;
  notvalidFunction: Function;
  ricaricaPannelli: Function;
  idSala: number;
};

class statoForm {
  pannelloForm: Pannello = new Pannello();

  formvalid: boolean = false;

  nomevalid: boolean = true;
  salavalid: boolean = true;

  nome_blur: boolean = false;
  sala_blur: boolean = false;


  risultato: RisultatoOperazione[] = [];

  constructor(pannello: Pannello) {
    this.pannelloForm = { ...pannello };
  }
}

enum inputId {
  Nome = 'inputName',
  Sala = "inputSala"
}

enum azioni {
  RESET = 'reset',
  SUBMIT = 'submit',
  FORM_CHANGE = 'update',
  CHANGE_NOME = 'changeNome',
  CHANGE_IDSALA = 'changeIdSala',
  BLUR_NOME = 'blurNome',
  BLUR_SALA = 'blurSala'
}

function reducer(state: statoForm, action: ReducerAction) {
  let stato = { ...state };

  switch (action.type) {

    case azioni.RESET: {
      stato = new statoForm((action as action_pannello).value);
      return stato;
    }

    case azioni.SUBMIT: {
      stato.risultato = (action as action_Risultati).value;
      stato = new statoForm(new Pannello());
      return stato;
    }

    case azioni.FORM_CHANGE: {
      stato = raccogliInput(stato);
      stato = convalidaForm(stato);
      return stato;
    }

    case azioni.CHANGE_NOME: {
      let nuovo = { ...state };
      nuovo.pannelloForm.nome = (action as action_string).value;
      stato = convalidaForm(stato);

      return nuovo;
    }

    case azioni.CHANGE_IDSALA: {
      let nuovo = { ...state };
      nuovo.pannelloForm.fkSala = (action as action_number).value;
      stato = convalidaForm(stato);

      return nuovo;
    }

    case azioni.BLUR_NOME: {
      stato.nome_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_SALA: {
      stato.sala_blur = true;
      stato = convalidaForm(stato);
      return stato
    }

    default: return state;
  }
}

const FormDialogPannello: React.FC<Props> = ({ _open, mostraModal, pannello, nomeSala, confirmFunction, errorFunction, notvalidFunction, ricaricaPannelli, idSala, confirmUpdate, confirmCreate }) => {

  const handleClose = () => { confirmFunction(); mostraModal(false); setLoading(false); ricaricaPannelli() };
  const handleAbort = (e: any) => { handleResetForm(e); mostraModal(false) };
  const handleNotValidInsert = () => { notvalidFunction(); checkNotValidInsertFields(); }
  const handleNotValidUpdate = () => { notvalidFunction(); checkNotValidUpdateFields(); }
  const handleError = () => { errorFunction(); mostraModal(false) };
  const [state, dispatch] = React.useReducer(reducer, new statoForm(pannello));
  const [sala, setSala] = React.useState(pannello.nome ? pannello.fkSala : 0);
  const [salaSelect, setSalaSelect] = React.useState(idSala);
  const [notValidNome, setNotValidNome] = React.useState(false);
  const [notValidSala, setNotValidSala] = React.useState(true);
  const [query, setQuery] = React.useState(new QueryModel());
  const [loading, setLoading] = React.useState(false);


  const { session, setSession } = useSession();

  const [listaSale, setListaSale] = React.useState<any>([]);

  const fetchSale = async () => {
    let q = new QueryModel();
    q.pageSize =  100;
    q.pageNumber =  1;
    q.sortBy = "Nome";

    let result = await SalaService.GetPaginated(q);
    const res = result.lista;
    console.log(res);
    setListaSale(res);
  }

  React.useEffect(() => { fetchSale(); }, []);
  useEffect(() => {
    setNotValidSala(!state.pannelloForm.fkSala);
  }, [state]);


  useEffect(() => {
    dispatch({ type: azioni.RESET, value: pannello })
  }, [pannello]);

  function checkNotValidInsertFields() {

    if (!state.pannelloForm.nome) setNotValidNome(true);
    handle_blur(azioni.BLUR_SALA)
  }

  function checkNotValidUpdateFields() {
    if (!pannello.nomeSala) setNotValidNome(true);
    if (!pannello.fkSala) setNotValidSala(true);
  }



  async function handle_submit() {

    if (state.pannelloForm.nome?.trim() && state.pannelloForm.fkSala) {

      setLoading(true);

      let risultati: RisultatoOperazione[] = [];

      console.log(state.pannelloForm)
      let success: boolean = await PannelloService.AddPannello(state.pannelloForm);
      console.log(success)
      let msg: string = (success) ? Dictionary.FormPannello.log_add_success : Dictionary.FormPannello.log_add_error;

      risultati.push(new RisultatoOperazione(success, msg));

      dispatch({ type: azioni.SUBMIT, value: risultati });
      (!success) ? handleError() : handleClose(); confirmCreate();
      //confirmFunction();
    } else {
      handleNotValidInsert();
    }
  }


  async function handle_update(salaSelect: number) {

    if (/*pannello.nome || */(state.pannelloForm.nome?.trim() && state.pannelloForm.fkSala)) {
      setLoading(true);
      console.log('sono in modifica')
      let _pannello = raccogliModifiche(pannello);

      let risultati: RisultatoOperazione[] = [];

      let success: boolean = await PannelloService.UpdatePannello(_pannello);
      console.log(success)
      let msg: string = (success) ? Dictionary.FormPannello.log_update_success : Dictionary.FormPannello.log_update_error;
      risultati.push(new RisultatoOperazione(success, msg));

      dispatch({ type: azioni.SUBMIT, value: risultati });
      (!success) ? handleError() : handleClose(); confirmUpdate();
    }
    else {
      handleNotValidUpdate();
    }
    //confirmFunction();
  }

  const handleChangeSala = (e: any) => {

    pannello.fkSala = e.target.value;

    //modifica valore visualizzato a schermo
    let inputSala = (document.getElementById(inputId.Sala) as HTMLInputElement);
    inputSala.valueAsNumber = e.target.value;

    setNotValidSala(false);
    setSala(e.target.value);
    setSalaSelect(e.target.value);
    dispatch({ type: azioni.CHANGE_IDSALA, value: e.target.value });
  };

  function handle_change(e: any) {

    dispatch({ type: azioni.CHANGE_NOME, value: e.target.value });
  };

  function handle_blur(campo: azioni) {
    dispatch({ type: campo });
  }

  function handleResetForm(e: any) {
    dispatch({ type: azioni.RESET, value: e.target.value })
  }

  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  /*impostazione overflow scroll in select*/
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  return (
    <div>
      <Dialog open={_open} onClose={(e) => handleAbort(e)}>
        <DialogTitle>{pannello.nome ? Dictionary.FormPannello.updateForm_title : Dictionary.FormPannello.addForm_title}</DialogTitle>
        <LoadingBar show={loading} />
        <DialogContent className='salaDialogContent' dividers>

          <InputLabel id="labelNome" color="success">Nome</InputLabel>
          <TextField
            className='inputNome'
            error={!state.pannelloForm.nome?.trim() && state.nome_blur ? true : false}
            color='success'
            autoFocus
            margin="dense"
            id="inputName"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={pannello.nome ? pannello.nome : ''}
            onChange={(e) => { handle_change(e) }}
            onBlur={() => { handle_blur(azioni.BLUR_NOME) }}
          />
          {pannello && <br />}
          {pannello && <br />}


          <FormControl variant="standard" fullWidth>

            <FormControl fullWidth variant="standard">
              <FormControl fullWidth variant="standard" >
                <InputLabel sx={{ fontSize: "22px" }} color='success' className='labelSala' id="demo-simple-select-label">{pannello.fkSala ? 'Sala' : 'Seleziona una sala'}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pannello.fkSala}
                  key={pannello.fkSala}
                  onChange={(e) => handleChangeSala(e)}
                  color="success"
                  label={pannello.nomeSala ? pannello.nomeSala : 'Seleziona una sala'}
                  error={ /*!pannello.nomeSala && !state.pannelloForm.nomeSala  &&*/ state.sala_blur && notValidSala}
                  onBlur={() => { handle_blur(azioni.BLUR_SALA) }}
                  MenuProps={MenuProps}>

                  {listaSale.map((sala: Sala) =>
                    <MenuItem key={sala.idSala} value={sala.idSala}>{sala.nome}</MenuItem>

                  )}
                </Select>
              </FormControl>

              <input type="number" id="inputSala" defaultValue={idSala} readOnly hidden={true} />

            </FormControl>
          </FormControl>
          <br /><br />

        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleAbort(e)} color="success">Annulla</Button>
          {pannello.nomeSala && <Button variant="contained" color="success"
            onClick={() => { handle_update(salaSelect); }} >
            Salva
          </Button>}
          {!pannello.nomeSala && <Button variant="contained" color="success"
            onClick={() => { handle_submit() }} >
            Salva
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );


}
export default FormDialogPannello;
function raccogliInput(stato: statoForm) {
  stato.pannelloForm.nome = (document.getElementById(inputId.Nome) as HTMLInputElement).value;
  stato.pannelloForm.fkSala = (document.getElementById(inputId.Sala) as HTMLInputElement).valueAsNumber;


  return stato;
}


function raccogliModifiche(pannello: Pannello) {
  pannello.nome = (document.getElementById(inputId.Nome) as HTMLInputElement).value;
  pannello.fkSala = (document.getElementById(inputId.Sala) as HTMLInputElement).valueAsNumber;


  return pannello;
}

function convalidaForm(stato: statoForm) {

  if (stato.pannelloForm.nome) {
    stato.formvalid = true;
  } else stato.formvalid = false;

  return stato;
}


