
import React, { useEffect, useState } from "react";
import CardInfoProfilo from "./CardInfoProfilo";
import FormModificaProfilo from "./FormModificaProfilo";
import { useSession } from "../../Context/SessionContext";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import TemporaryDrawerLeft from "../../Layouts/Main/TemporaryDrawerLeft";
import './ProfiloUtente.css';
import GenericToast from "../../Layouts/Main/GenericToast";

export class ToastSet {
    show = false;
    success = true;
    successmsg = 'operazione riuscita';
    failmsg = 'modifica non riuscita';
}

const ProfiloUtente: React.FC = () => {
    const { session, setSession } = useSession();
    const [editing, setEditing] = useState(false);
    const [toast, setToast] = useState(new ToastSet());

    function showToast(x: boolean): void {
        setEditing(false);
        setToast({ ...toast, show: x });
    }


    return <>
        <TemporaryDrawerLeft _titoloSezione={'Profilo: ' + session.utente.userName}>


            <Box component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }} >

                <Grid item xs={12} sm={12} md={12}>
                    <Typography paragraph sx={{textAlign:'left'}}>

                        {(!editing) ?
                            <>
                                <Paper className="card-profilo" elevation={3}>
                                    <CardInfoProfilo utente={session.utente}
                                    />
                                </Paper>
                                <Button className='btnpad' variant="contained" color="success"
                                    onClick={() => { setEditing(true) }}>
                                    Modifica Password
                                </Button>

                            </>
                            :
                            <>
                                <FormModificaProfilo
                                    utente={session.utente}
                                    onCancel={() => { setEditing(false) }}
                                    onConfirm={(toast: ToastSet) => { setEditing(false); setToast(toast); }}
                                />
                            </>
                        }
                    </Typography>
                </Grid>
            </Box >

        </TemporaryDrawerLeft>



        <GenericToast
            _open={toast.show}
            mostraModal={showToast}
            _autoClose={4000}
            _horizontal="right"
            _vertical="bottom"
            _message={toast.success ? toast.successmsg : toast.failmsg}
            _severity={toast.success ? "success" : "error"}
        ></GenericToast>
    </>

}

export default ProfiloUtente;
