import { LinearProgress } from "@mui/material";

type LoadingBarProps = {
    width?: string
    show: boolean
}

const LoadingBar: React.FC<LoadingBarProps> = ({ width, show }) => {
    return <>
        {show ?
            <LinearProgress color="success" style={{ width: width }} />
            :
            <div style={{ height: '4px', width: width }}></div>
        }
    </>
}
export default LoadingBar;