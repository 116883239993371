import { Box, Typography, Paper, Grid, ListItem, FormControl, Input, InputLabel, Divider, ToggleButtonGroup, Switch, FormControlLabel, Button, IconButton, Alert, Snackbar, Tooltip, Fade, } from '@mui/material';
import React, { Component, useCallback, useEffect, useState } from 'react';
import "./Settings2.css";
import TemporaryDrawerLeft from '../../Layouts/Main/TemporaryDrawerLeft';
import Dictionary from '../../Config/dictionary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import GenericToast from '../../Layouts/Main/GenericToast';
import GenericAlert from '../../Layouts/Main/GenericAlert';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SessionManager } from '../../Services/SessionManager';
import { useSession } from '../../Context/SessionContext';
import { ImpostazioniGenerali } from '../../Models/ImpostazioniGenerali';
import ImpostazioniService from '../../Services/ImpostazioniService';
import RisultatoOperazione from '../../Models/RisultatoOperazione';
import { action_Risultati, action_settings, ReducerAction } from '../../Services/Reducers/ReducerAction';
import { TextField } from '@material-ui/core';

class statoForm {
  settingsForm: ImpostazioniGenerali = new ImpostazioniGenerali();

  formvalid: boolean = false;

  IBvalid: boolean = true;
  stepvalid: boolean = true;
  timervalid: boolean = true;
  checkInvalid: boolean = true;

  step_blur: boolean = false;
  timer_blur: boolean = false;


  risultato: RisultatoOperazione[] = [];

  constructor(settings: ImpostazioniGenerali) {
    this.settingsForm = { ...settings };
  }
}

enum inputId {
  IB = 'inputIB',
  STEP = "inputStep",
  TIMER = "inputTimer",
  CHECKIN = "checkIn"
}

enum azioni {
  RESET = 'reset',
  SUBMIT = 'submit',
  FORM_CHANGE = 'update',
  BLUR_STEP = 'blurStep',
  BLUR_TIMER = 'blurTimer'
}

function reducer(state: statoForm, action: ReducerAction) {
  let stato = { ...state };

  switch (action.type) {

    case azioni.RESET: {
      stato = new statoForm((action as action_settings).value);
      return stato;
    }

    case azioni.SUBMIT: {
      stato.risultato = (action as action_Risultati).value;
      return stato;
    }

    case azioni.FORM_CHANGE: {
      stato = raccogliInput(stato);
      stato = convalidaForm(stato);
      return stato;
    }

    case azioni.BLUR_STEP: {
      stato.step_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_TIMER: {
      stato.timer_blur = true;
      stato = convalidaForm(stato);
      return stato
    }

    default: return state;
  }
}



const Settings = () => {

  const [openOption, setOpenOption] = useState('');
  const [openApplyAlert, setOpenApplyAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const [openInvalidFieldAlert, setOpenInvalidFieldAlert] = React.useState(false);
  const [openUpdateAlert, setOpenUpdateAlert] = React.useState(false);
  const [showApplyDialog, setShowApplyDialog] = useState(false);
  const [settings, setSettings] = React.useState<any>([]);
  const [iB, setIB] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [passo, setPasso] = useState(0);
  const [checkInTimer, setcheckInTimer] = useState(0);
  const [configInstantBooking, setConfigInstantBooking] = useState(false);
  const [configCheckIn, setConfigCheckIn] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, new statoForm(settings));
  const [idSettings, setIdSettings] = useState(1);
  const [isSettingsChanged, setIsSettingsChanged] = useState(false);
  const [passoNotValid, setPassoNotValid] = useState(false);
  const [URNotValid, setURNotValid] = useState(false);
  const { session, setSession } = useSession();


  const step = (document.getElementById(inputId.STEP));

  if (step != null) {
    step.onkeydown = function (e) {
      if (e.key == '-') {
        return false;
      }
    }
    step.onpaste = function (e) {
      e.preventDefault();
      return false;
    }
  }

  const timerUR = (document.getElementById(inputId.TIMER));

  if (timerUR != null) {
    timerUR.onkeydown = function (e) {
      if (e.key == '-') {
        return false;
      }
    }
    timerUR.onpaste = function (e) {
      e.preventDefault();
      return false;
    }
  }

  useEffect(() => { fetchSettings(); }, []);

  const handleIB = () => {
    console.log("cambio IB")
    if(configInstantBooking==true){
    setIB(!iB);
    }else{
      console.log("Instant Booking disattivato in File Config")
      setIB(false);
    }
  }
  const handleCheckIn = () => {
    console.log("cambio CheckIn")
    if(configCheckIn==true){
    setCheckIn(!checkIn);
    }else{
      console.log("CheckIn disattivato in File Config")
      setCheckIn(false);
    }
  }

  const fetchSettings = async () => {

    const res = await ImpostazioniService.GetAllImpostazioni();
    console.log(res)
    setSettings(await res[0]);

    setIdSettings(res[0].idImpostazioniGenerali as number)
    setPasso(res[0].passo as number)
    setIB(res[0].instantBooking as boolean);
    setcheckInTimer(res[0].checkInTimer as number);
    setConfigInstantBooking(res[0].configInstantBooking as boolean);
    setCheckIn(res[0].checkIn as boolean);
    setConfigCheckIn(res[0].configCheckIn as boolean);

  }


  const handleError = () => {
    setOpenErrorAlert(true);
  }

  const handleConfirm = () => {

    if ((state.settingsForm.passo && state.settingsForm.passo < 0) || (state.settingsForm.checkInTimer && state.settingsForm.checkInTimer < 0)) {

      if (state.settingsForm.passo && state.settingsForm.passo < 0) { setPassoNotValid(true); }
      if (state.settingsForm.checkInTimer && state.settingsForm.checkInTimer < 0) { setURNotValid(true); }
      setOpenInvalidFieldAlert(true);

    } else {



      setShowApplyDialog(true);

    }
  }

  const handleClose = () => {
    setIsSettingsChanged(false);
    fetchSettings();
    setShowApplyDialog(false);
    setOpenUpdateAlert(true);
  }

  const handleInvalidForm = () => {

    if (state.settingsForm.passo && state.settingsForm.passo < 0) { setPassoNotValid(true); }
    if (state.settingsForm.checkInTimer && state.settingsForm.checkInTimer < 0) { setURNotValid(true); }

  }


  async function handle_submit() {

    let risultati: RisultatoOperazione[] = [];

    let impostazioniDefault = new ImpostazioniGenerali(idSettings, iB, configInstantBooking, passo, checkInTimer, checkIn, configCheckIn);

    console.log('modificheForm', state.settingsForm)
    console.log('impostazioniDefault', impostazioniDefault)

    let success: boolean;
    if (state.settingsForm.passo == impostazioniDefault.passo && state.settingsForm.checkInTimer == impostazioniDefault.checkInTimer
      && state.settingsForm.instantBooking == impostazioniDefault.instantBooking && state.settingsForm.checkIn == impostazioniDefault.checkIn) {

      if (Number.isNaN(state.settingsForm.passo)) { impostazioniDefault.passo = 0 }
      if (Number.isNaN(state.settingsForm.checkInTimer)) { impostazioniDefault.checkInTimer = 0 }

      success = await ImpostazioniService.UpdateImpostazioni(impostazioniDefault, session.accessToken);
    } else {

      if (Number.isNaN(state.settingsForm.passo)) { state.settingsForm.passo = 0 }
      if (Number.isNaN(state.settingsForm.checkInTimer)) { state.settingsForm.checkInTimer = 0 }
      success = await ImpostazioniService.UpdateImpostazioni(state.settingsForm, session.accessToken);
    }
    console.log(success)
    let msg: string = (success) ? "impostazioni modificate" : "impostazioni non modificate";

    risultati.push(new RisultatoOperazione(success, msg));

    dispatch({ type: azioni.SUBMIT, value: risultati });
    (!success) ? handleError() : handleClose();
  
  }


  function handle_blur(campo: azioni) {
    dispatch({ type: campo });
  }

  function handle_change() {
    if (state.settingsForm.passo || state.settingsForm.passo == 0) { setPassoNotValid(false); }
    if (state.settingsForm.checkInTimer || state.settingsForm.checkInTimer == 0) { setURNotValid(false); }

    setIsSettingsChanged(true);

    dispatch({ type: azioni.FORM_CHANGE });
  };

  function handleResetForm() {
    window.location.reload();
  }


  return <>
    <TemporaryDrawerLeft _titoloSezione={Dictionary.Settings.settings_sectionTitle}>


      <Box //component="main" 
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }} >


        <Grid sx={{ margin: '0', width: '100%' }}>

          <Paper elevation={4} className='section-card'>

            <Box component="h2" className="section-header">Generali</Box>
            <Divider></Divider>
            <Box className='section-body'>

              <div className='section-row'>

              <Tooltip color='success' arrow TransitionComponent={Fade} placement='right' 
              hidden={configCheckIn == true ? true : false} disableHoverListener={configCheckIn == true ? true : false} title="Funzionalità disattivata.">

                <FormControl className="input-group" variant="standard">
                  <FormControlLabel className='setting-label' control={<p></p>} label="CheckIn" />
                  <Switch id="checkIn" disabled={!configCheckIn} checked={configCheckIn==true? checkIn : false} className='setting-switch' color="success" onChange={() => { handle_change() }} onClick={() => handleCheckIn()} />
                </FormControl>
                </Tooltip>

                <FormControl className="input-group" variant="standard">
                  <FormControlLabel className='setting-label' control={<p></p>} label={"Check-in Timer"} />
                  <Input color="success" id="inputTimer" className='setting-numfield' key={checkInTimer} defaultValue={checkInTimer}
                    error={state.settingsForm.checkInTimer == NaN || state.settingsForm.checkInTimer && state.settingsForm.checkInTimer < 0/* ||!state.settingsForm.unlockRoom */ ? true : false}
                    onChange={() => { handle_change() }} type='number' inputProps={{ step: "10", min: 0 }} />

                </FormControl>

              </div>
            </Box>
          </Paper>

          <Paper elevation={4} className='section-card'>

            <Box component="h2" className="section-header">Instant Booking</Box>
            <Divider></Divider>
            <Box className='section-body'>
              
              <div className='section-row'>
              <Tooltip color='success' arrow TransitionComponent={Fade} placement='right' 
              hidden={configInstantBooking == true ? true : false} disableHoverListener={configInstantBooking == true ? true : false} title="Funzionalità disattivata.">

                <FormControl className="input-group" variant="standard">
                  <FormControlLabel className='setting-label' control={<p></p>} label="Attivazione" />
                  <Switch id="inputIB" disabled={!configInstantBooking} checked={configInstantBooking==true? iB : false} className='setting-switch' color="success" onChange={() => { handle_change() }} onClick={() => handleIB()} />
                </FormControl>
                </Tooltip>

                <FormControl className="input-group" variant="standard">
                  <FormControlLabel className='setting-label' control={<p></p>} label="Step" />

                  <Input color="success" className='setting-numfield' id="inputStep" key={passo} defaultValue={passo}
                    error={state.settingsForm.passo == NaN || state.settingsForm.passo && state.settingsForm.passo < 0/* ||!state.settingsForm.passo */ ? true : false}
                    type='number' onBlur={() => handle_blur(azioni.BLUR_STEP)} onChange={() => { handle_change() }} inputProps={{ step: "10", min: 0 }} />

                </FormControl>
              
              </div>
            </Box>
          </Paper>
          <br />

          <Grid className="" container/* rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={{ xs: 2, md: 0.5 }} columns={{ xs: 4, sm: 8, md: 12 }}*/>
           
            <Grid item xs={1} sm={1} md={1}>

              <Button className="button_save" disabled={!isSettingsChanged} variant="contained" color="success" size="medium" onClick={handleConfirm} > Applica</Button>
            </Grid>
          </Grid>

        </Grid>

        <input type="number" id="idSettings" value={idSettings} readOnly hidden={true} />
      </Box>
    </TemporaryDrawerLeft>


    <GenericToast _open={openApplyAlert} _vertical='bottom' _horizontal="right" _severity='success'
      _message={Dictionary.Settings.change_confirm_alert} mostraModal={setOpenApplyAlert}
      _autoClose={3000}></GenericToast>

    {/*DIALOG PER CONFERMA UPDATE*/}
    <GenericAlert confirmFunction={handle_submit} _open={showApplyDialog} _titolo={Dictionary.Settings.warning_change_configuration} _nomeSala={"settings"} mostraModal={setShowApplyDialog}
      messaggioConferma={Dictionary.Settings.explanation_change_warning } />

    {/*TOAST ERRORE */}

    <GenericToast _open={openErrorAlert} _vertical='bottom' _horizontal="right" _severity='error'
      _message={"Operazione fallita."} mostraModal={setOpenErrorAlert}
      _autoClose={4000} ></GenericToast>

    {/*TOAST INVALID FIELD */}

    <GenericToast _open={openInvalidFieldAlert} _vertical='bottom' _horizontal="right" _severity='error'
      _message={!passoNotValid ? 'Il timer non può essere minore di zero.' : !URNotValid ? 'Il passo non può essere minore di zero.' : 'Il passo ed il timer non possono essere minori di zero.'} mostraModal={setOpenInvalidFieldAlert}
      _autoClose={3000} ></GenericToast>

    {/*TOAST CONFERMA UPDATE*/}

    <GenericToast _open={openUpdateAlert} _vertical='bottom' _horizontal="right" _severity='success'
      _message={Dictionary.Settings.change_confirm_alert} mostraModal={setOpenUpdateAlert}
      _autoClose={3000} ></GenericToast>

  </>

}
export default Settings;




function raccogliInput(stato: statoForm) {
  stato.settingsForm.idImpostazioniGenerali = (document.getElementById('idSettings') as HTMLInputElement).valueAsNumber;
  stato.settingsForm.instantBooking = (document.getElementById(inputId.IB) as HTMLInputElement).checked;
  stato.settingsForm.passo = (document.getElementById(inputId.STEP) as HTMLInputElement).valueAsNumber;
  stato.settingsForm.checkInTimer = (document.getElementById(inputId.TIMER) as HTMLInputElement).valueAsNumber;
  stato.settingsForm.checkIn = (document.getElementById(inputId.CHECKIN) as HTMLInputElement).checked;

  return stato;
}


function convalidaForm(stato: statoForm) {

  if (stato.settingsForm.instantBooking) {
    stato.formvalid = true;
  } else stato.formvalid = false;

  return stato;
}