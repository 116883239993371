export class SignageBook {
    idSignageBook?: number;
    nomeFile?: string;
    file?: Blob | string;
    fkSala?: number;

    constructor(idSignageBook?: number, nomeFile?: string, file?: Blob  | string, fkSala?: number) {

        this.idSignageBook = idSignageBook;
        this.nomeFile = nomeFile;
        this.file = file;
        this.fkSala = fkSala;
    }

    public static costruttoreEsteso(
        idSignageBook: number,
        nomeFile: string,
        file: Blob  | string,
        fkSala: number,

    ) {

        let signageBook = new SignageBook();

        if (idSignageBook) signageBook.idSignageBook = idSignageBook;
        if (nomeFile) signageBook.nomeFile = nomeFile;
        if (file) signageBook.file = file;
        if (fkSala) signageBook.fkSala = fkSala;

        return signageBook;
    }

}