import { createContext, useContext } from 'react';
import { AppSession } from '../Services/SessionManager';


export type sessionContextType = {
    session: AppSession;
    setSession: (session: AppSession) => void;
}

export const SessionContext = createContext<sessionContextType>(
    {
        session: new AppSession(),
        setSession: session => console.warn('no session provider')
    }
);

export const useSession = () => useContext(SessionContext);
