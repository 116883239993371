import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { action_bool, action_card, action_string, ReducerAction } from "../../Services/Reducers/ReducerAction";
import { Expressions } from '../../Services/Enums/Expressions';
import "./FormDialogCard.css";
import Dictionary from '../../Config/dictionary';
import LoadingBar from '../../Layouts/LoadingBar';
import GenericToast from '../../Layouts/Main/GenericToast';
import { Card } from '../../Models/Card';
import CardService from '../../Services/CardService';

const ui_text = Dictionary.GestioneCard;

type Props = {
  _open: boolean;
  mostraModal: Function;
  card: Card;
  confirmFunction: Function;
  errorFunction: Function;
  notvalidFunction: Function;
};

class statoForm {
  cardForm: Card = new Card();

  emailValid: boolean = false;
  CodeNotAvailableMsg: string = '';
  CodeValid: boolean = false;

  submitted: boolean = false;
  modified: boolean = false;

  constructor(card: Card) {
    this.cardForm = { ...card };
    this.emailValid = card.user !== null && card.user.length > 0 && Expressions.EMAIL_FORMAT_REGEX.test(card.user);
    this.CodeValid = card.identifier !== null && card.identifier.length > 0;
  }
}

enum inputId {
  email = 'input_email',
  code = 'input_cardcode'
}

enum azioni {
  RESET = 'reset',
  SUBMIT = 'submit',
  EMAIL_CHANGE = 'emailchange',
  CODE_CHANGE = 'cardcodechange',
  IDENTIFIER_NOTAVAILABLE = 'cardcode_notavailable',
}


function reducer(state: statoForm, action: ReducerAction) {
  let stato = { ...state };

  switch (action.type) {

    case azioni.RESET: {
      stato = new statoForm((action as action_card).value);
      break;
    }

    case azioni.SUBMIT: {
      stato.submitted = true;
      break;
    }

    case azioni.EMAIL_CHANGE: {
      let input = (action as action_string).value.trim();
      stato.cardForm.user = input;
      stato.emailValid = Expressions.EMAIL_FORMAT_REGEX.test(input);
      stato.modified = true;
      break;
    }

    case azioni.CODE_CHANGE: {
      let input = (action as action_string).value.trim();
      stato.cardForm.identifier = input;
      stato.CodeValid = stato.cardForm.identifier.length > 0;
      stato.modified = true;
      break;
    }

    case azioni.IDENTIFIER_NOTAVAILABLE: {
      let codeUnavailable = (action as action_bool).value;
      if (codeUnavailable) {
        stato.CodeValid = false;
        stato.CodeNotAvailableMsg = ui_text.IdentifierNotAvailable;
      } else {
        stato.CodeNotAvailableMsg = '';
      }

      break;
    }
  }

  return stato;
}

function noSpace(e: React.KeyboardEvent) {
  let key = e.key;
  if (key === ' ') e.preventDefault();
}

const FormDialogCard: React.FC<Props> = ({ _open, mostraModal, card, confirmFunction, errorFunction, notvalidFunction }) => {
  const [state, dispatch] = React.useReducer(reducer, new statoForm(card));
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastSuccess, setToastSuccess] = useState(false);

  const handleAbort = () => { mostraModal(false) };

  useEffect(() => { dispatch({ type: azioni.RESET, value: card }) }, [card]);
  useEffect(() => { checkCodeExist(); }, [state.cardForm.identifier]);

  async function checkCodeExist() {
    let notAvailable = false;
    if (state.CodeValid && state.cardForm.identifier !== card.identifier) {
      let existing = await CardService.getByIdentifier(state.cardForm.identifier);
      notAvailable = existing.id ? true : false;
    }
    dispatch({ type: azioni.IDENTIFIER_NOTAVAILABLE, value: notAvailable });
  }

  async function handle_submit() {

    if (!state.emailValid || !state.CodeValid) {
      // se campi form non corretti, segnala tentativo submit in dispatch per mostrare errori nel form
      dispatch({ type: azioni.SUBMIT });

    } else if (!state.modified) {
      //se nessuna digitazione avvenuta, chiudi modal senza fare operazioni
      mostraModal(false);
    } else {
      //esegui request add/update utente
      setLoading(true);
      let success: boolean = (state.cardForm.id) ?
        await CardService.Update(state.cardForm)
        :
        await CardService.Add(state.cardForm);

      // a seconda esito operazione richiama toast errore/conferma da funzione in props
      setLoading(false);
      mostraModal(false);

      if (success) confirmFunction();
      else errorFunction();

      setToastSuccess(success);
      setShowToast(true);
    }
  }

  function getToastMsg() {
    let msg: string = '';

    if (card.id)
      msg = toastSuccess ? ui_text.card_modificato : ui_text.card_non_modificato
    else
      msg = toastSuccess ? ui_text.card_creato : ui_text.card_non_creato

    return msg;
  }

  return (<>
    <Dialog className="utenteDialog" open={_open} onClose={handleAbort}>
      <DialogTitle>
        {card.id ? ui_text.modif_card : ui_text.crea_card}
      </DialogTitle>

      <LoadingBar show={loading} />

      <DialogContent className='utenteDialogContent' dividers>

        <TextField //EMAIL INPUT 
          type='email' className={inputId.email}
          color='success' margin="dense" fullWidth variant="standard"
          error={state.submitted && !state.emailValid}

          id={inputId.email}
          label="Email"
          defaultValue={card.id ? card.user : ''}
          onKeyDown={(e) => { noSpace(e) }}
          onChange={(e) => { dispatch({ type: azioni.EMAIL_CHANGE, value: e.target.value }); }}
        />
        <div style={{ minHeight: '1rem', color: 'red', fontSize: '.7rem' }}>
          {(state.submitted && !state.emailValid && state.cardForm.user) && "formato email non valido"}
        </div>

        <TextField //CARD CODE INPUT 
          type='text' className={inputId.code}
          color='success' margin="dense" fullWidth variant="standard"
          error={state.submitted && !state.CodeValid}

          id={inputId.email}
          label="Codice"
          defaultValue={card.id ? card.identifier : ''}
          onKeyDown={(e) => { noSpace(e) }}
          onChange={(e) => { dispatch({ type: azioni.CODE_CHANGE, value: e.target.value }); }}
        />
        <div style={{ minHeight: '1rem', color: 'red', fontSize: '.7rem' }}>
          {(state.submitted && !state.CodeValid && state.CodeNotAvailableMsg) && state.CodeNotAvailableMsg}
        </div>



      </DialogContent>
      <DialogActions>
        <Button className='btnpad' onClick={handleAbort} color="success">Annulla</Button>

        <Button className='btnpad' variant="contained" color="success"
          onClick={handle_submit}>
          Salva
        </Button>

      </DialogActions>
    </Dialog>

    {/*TOAST CONFERMA*/}
    <GenericToast _open={showToast} _vertical='bottom' _horizontal="right"
      _severity={toastSuccess ? 'success' : 'error'}
      _message={getToastMsg()}
      mostraModal={setShowToast}
      _autoClose={3000} />
  </>
  );


}
export default FormDialogCard;
