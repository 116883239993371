import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import { Button, IconButton } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericToast from './GenericToast';
import Dictionary from '../../Config/dictionary';
import "./SearchAppBar.css";


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
   /*marginLeft: theme.spacing(1),*/
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

type Props = {
  ricerca: Function;
  onAdd?: Function;
};

const SearchAppBar: React.FC<Props> = ({ ricerca, onAdd }) => {

  const [openCreateAlert, setOpenCreateAlert] = React.useState(false);
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);

  function handleAdd() {
    if (onAdd) onAdd();
  }

  function handleSearch() {
    let input = (document.getElementById('searchbar_input') as HTMLInputElement).value.trim();
    ricerca(input);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ backgroundColor: "white", color: "black", borderRadius:'4px' }} position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between", 
        paddingLeft:'1rem!important',
        paddingRight:'.8rem!important' }}>

          <Box sx={{ display: "flex" }}>
            <form id='formid_searchbar' onSubmit={(e) => { e.preventDefault(); handleSearch() }}>
              <Search className="searchField" >
                <SearchIconWrapper>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="magnIcon" size="lg" />
                </SearchIconWrapper>
                <StyledInputBase
                  id='searchbar_input'
                  placeholder="Ricerca..."
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
            </form>
          </Box>
          {/* tasto add per visualizzazione mobile */}
          <IconButton color="success" size="medium" onClick={handleAdd}><FontAwesomeIcon className='plusIcon' icon={faPlus} size="lg" /></IconButton>

          <Button className="button_addEdit btnpad" variant="outlined" color="success" size="medium" onClick={handleAdd}>
            <FontAwesomeIcon className='plusIcon' icon={faPlus} size="lg" />
            Aggiungi</Button>


        </Toolbar>
      </AppBar>


      {/*TOAST CONFERMA CREATE */}

      <GenericToast _open={openCreateAlert} _vertical='bottom' _horizontal="right" _severity='success'
        _message={Dictionary.SearchBar.create_confirm_alert} mostraModal={setOpenCreateAlert}
        _autoClose={3000}></GenericToast>

      {/*TOAST ERRORE */}

      <GenericToast _open={openErrorAlert} _vertical='bottom' _horizontal="right" _severity='error'
        _message={"Operazione fallita."} mostraModal={setOpenErrorAlert}
        _autoClose={3000} ></GenericToast>
    </Box>

  );
}

export default SearchAppBar;
