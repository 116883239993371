import axios from "axios";
import { convertToObject } from "typescript";
import { Endpoints } from "../Config/Endpoints";
import { Pannello } from "../Models/Pannello";
import { SessionManager } from "./SessionManager";
import { QueryModel } from "./Utils/QueryModel";
import { RequestConfig } from "./Utils/RequestConfig";

export default class SalaService {
    public static DestrutturazioneSala(jsnProd: any) {
        return new Pannello(
            jsnProd.risultato.id,
            jsnProd.risultato.nome,
            jsnProd.risultato.sala)
    }

    public static async getCount(): Promise<number> {

        return await axios.get(Endpoints.Pannello.get_Count, RequestConfig.queryparams({}))
            .then((res) => {
                console.log(res.data);
                SessionManager.RefreshSession();
                return res.data;

            }, (error: any) => {
                console.log(error);
                return 0;
            });

    }

    public static async GetPaginated(model: QueryModel): Promise<pagedPannello> {

        let pagedlist = new pagedPannello();

        return await axios.post(Endpoints.Pannello.get_All, model, RequestConfig.queryparams({}))
            .then((res) => {


                console.log('PAGINATED SEARCH');
                console.log(res.data);
                pagedlist.count = parseInt(res.headers.totalcount);
                pagedlist.currentPage = parseInt(res.headers.currentpage);
                console.log(res.headers.totalcount)

                for (let DTO of res.data) {
                    pagedlist.lista.push({
                        idPannello: DTO.idPannello,
                        nome: DTO.nome,
                        fkSala: DTO.fkSala,
                        nomeSala: DTO.nomeSala,
                        dataUltimoPolling: DTO.dataUltimoPolling,
                        pollingInterval: DTO.pollingInterval,
                        statoPannello: DTO.statoPannello
                    });
                }

                SessionManager.RefreshSession();
                return pagedlist;

            }, (error: any) => {
                console.log(error);
                return pagedlist;
            });

    }

    public static async GetAllPannello() {



        let pannelli: Array<Pannello> = [];
        let saleAbbinate: any;
        await axios.get(Endpoints.Pannello.get_Pannello_with_Sale, RequestConfig.queryparams())

            .then((response: any) => {

                console.log(response.data)
                pannelli = response.data;

                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        SessionManager.RefreshSession();
        return pannelli;
    }

    public static RemovePannello = async function (id: number | undefined) {

        console.log(id)

        let success: boolean = false;

        await axios.delete(Endpoints.Pannello.delete + id, RequestConfig.deletedata())
            .then((response) => {
                console.log(response, "Pannello eliminato");
                SessionManager.RefreshSession();
                success = true;
            }, (error) => {
                console.log(error, "Pannello non eliminato");
            });

        return success;
    }

    public static AddPannello = async function (pannello: Pannello) {

        console.log(pannello);

        return await axios.post(Endpoints.Pannello.post_Add, pannello, RequestConfig.queryparams())
            .then((response) => {
                console.log(response, "aggiunta effettuata ");
                console.log(response.data.id)
                SessionManager.RefreshSession();
                return true;
            }, (error) => {

                console.log(error, "chiamata effettuata ma nessun Pannello aggiunto");
                return false;
            });
    }

    public static UpdatePannello = async function (pannello: Pannello) {

        console.log(pannello)
        return await axios.put(Endpoints.Pannello.put_Update, pannello, RequestConfig.queryparams())
            .then((response) => {
                console.log(response, "modifica effettuata ");
                SessionManager.RefreshSession();
                return true;
            }, (error) => {

                console.log(error, "chiamata effettuata ma nessuna Pannello modificato");
                return false;
            });
    }

}
export class pagedPannello {
    count: number = 0;
    currentPage: number = 0;
    lista: Pannello[] = [];
}