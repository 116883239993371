import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Fade, InputLabel, Switch, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Sala } from '../../Models/Sala';
import Dictionary from '../../Config/dictionary';
import "./FormDialogSala.css";
import RisultatoOperazione from "../../Models/RisultatoOperazione";
import { action_Risultati, action_sala, ReducerAction } from "../../Services/Reducers/ReducerAction";
import SalaService from "../../Services/SalaService";
import { useSession } from '../../Context/SessionContext';
import GenericToast from '../../Layouts/Main/GenericToast';
import LoadingBar from '../../Layouts/LoadingBar';
import { Expressions } from '../../Services/Enums/Expressions';
import { Config } from '../../Models/Config';

type Props = {
  _open: boolean;
  mostraModal: Function;
  sala: Sala;
  confirmFunction: Function;
  confirmUpdate: Function;
  confirmCreate: Function;
  errorFunction: Function;
  notvalidFunction: Function;
  configurazioneIB: boolean;
  configurazioneCheckIn: boolean;
  config: Config;
};

class statoForm {
  salaForm: Sala = new Sala();

  formvalid: boolean = false;

  accountvalid: boolean = true;
  attvalid: boolean = true;
  capvalid: boolean = true;
  descvalid: boolean = true;
  instantvalid: boolean = true;
  nomevalid: boolean = true;
  permElencoValid: boolean = true;
  permSpotValid: boolean = true;
  permStatoValid: boolean = true;
  messaggioNonDispValid: boolean = true;
  checkInValid: boolean = true;


  acc_blur: boolean = false;
  att_blur: boolean = false;
  instant_blur: boolean = false;
  permElenco_blur: boolean = false;
  permSpot_blur: boolean = false;
  permStato_blur: boolean = false;
  nome_blur: boolean = false;
  desc_blur: boolean = false;
  cap_blur: boolean = false;
  messaggioNonDisp_blur: boolean = false;
  checkIn_blur: boolean = false;

  risultato: RisultatoOperazione[] = [];

  constructor(sala: Sala) {
    this.salaForm = { ...sala };
  }
}

enum inputId {
  Nome = 'inputNomeSala',
  Descrizione = "descrizione",
  Capienza = 'capienza',
  Account_Exchange = "accountExchange",
  InstantBooking = "InstantBooking",
  Attivazione = "Attivazione",
  MessaggioNonDisp = "MessaggioNonDisponibile",
  Check_In = "CheckIn",
  Perm_Elenco = "permElenco",
  Perm_Spot = "permSpot",
  Perm_Stato = "permStato"
}

enum azioni {
  RESET = 'reset',
  SUBMIT = 'submit',
  FORM_CHANGE = 'update',
  BLUR_NOME = 'blurNome',
  BLUR_DES = 'blurDes',
  BLUR_CAP = 'blurCapienza',
  BLUR_ACCOUNT = 'blurAccountExchange',
  BLUR_INSTANT_BOOKING = 'blurInstantBooking',
  BLUR_ATTIVAZIONE = 'blurAtt',
  BLUR_MESSAGGIO_NON_DISP = 'blurMessaggioNonDisp',
  BLUR_CHECK_IN = "checkIn",
  BLUR_PERM_ELENCO = "blurPermElenco",
  BLUR_PERM_SPOT = "blurPermSpot",
  BLUR_PERM_STATO = "blurPermStato"
}

function reducer(state: statoForm, action: ReducerAction) {
  let stato = { ...state };

  switch (action.type) {

    case azioni.RESET: {
      stato = new statoForm((action as action_sala).value);
      return stato;
    }

    case azioni.SUBMIT: {
      stato.risultato = (action as action_Risultati).value;

      return stato;
    }

    case azioni.FORM_CHANGE: {
      stato = raccogliInput(stato);
      stato = convalidaForm(stato);
      return stato;
    }

    case azioni.BLUR_NOME: {
      stato.nome_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_DES: {
      stato.desc_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_CAP: {
      stato.cap_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_ACCOUNT: {
      stato.acc_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_ATTIVAZIONE: {
      stato.att_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_INSTANT_BOOKING: {
      stato.instant_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_MESSAGGIO_NON_DISP: {
      stato.messaggioNonDisp_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_PERM_ELENCO: {
      stato.permElenco_blur = true;
      stato = convalidaForm(stato);
      return stato
    }
    case azioni.BLUR_PERM_SPOT: {
      stato.permSpot_blur = true;
      stato = convalidaForm(stato);
      return stato
    } case azioni.BLUR_PERM_STATO: {
      stato.permStato_blur = true;
      stato = convalidaForm(stato);
      return stato
    }

    default: return state;
  }
}

const FormDialogSala: React.FC<Props> = ({ _open, mostraModal, sala, confirmFunction, errorFunction, notvalidFunction, configurazioneIB, confirmUpdate, confirmCreate, configurazioneCheckIn, config }) => {
  const { session, setSession } = useSession();

  const handleClose = () => { confirmFunction(); setLoading(false); setToggleAttivazioneSala(sala.attivazione); mostraModal(); };
  const handleNotValidInsert = () => { notvalidFunction(); checkNotValidInsertFields(); }
  const handleNotValidUpdate = () => { notvalidFunction(); checkNotValidUpdateFields(); }
  const handleError = () => { errorFunction(); mostraModal() };
  const handleAbort = () => { mostraModal(); setToggleAttivazioneSala(sala.attivazione) };
  const [state, dispatch] = React.useReducer(reducer, new statoForm(sala));
  const [toggleAttivazioneSala, setToggleAttivazioneSala] = React.useState(sala.attivazione);
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const [isAccountExchangeValid, setIsAccountExchangeValid] = React.useState(true);
  const [notValidNome, setNotValidNome] = React.useState(false);
  const [notValidCapienza, setNotValidCapienza] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastSuccess, setToastSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);


  const capienza = (document.getElementById(inputId.Capienza));

  if (capienza != null) {
    capienza.onkeydown = function (e) {
      if (e.key == '-' || e.key == '.' || e.key == ',') {
        return false;
      }
    }
    capienza.onpaste = function (e) {
      e.preventDefault();
      return false;
    }
  }

  const permElenco = (document.getElementById(inputId.Perm_Elenco));

  if (permElenco != null) {
    permElenco.onkeydown = function (e) {
      if (e.key == '-' || e.key == '.' || e.key == ',') {
        return false;
      }
    }
    permElenco.onpaste = function (e) {
      e.preventDefault();
      return false;
    }
  }

  const permSpot = (document.getElementById(inputId.Perm_Spot));

  if (permSpot != null) {
    permSpot.onkeydown = function (e) {
      if (e.key == '-' || e.key == '.' || e.key == ',') {
        return false;
      }
    }
    permSpot.onpaste = function (e) {
      e.preventDefault();
      return false;
    }
  }

  const permStato = (document.getElementById(inputId.Perm_Stato));

  if (permStato != null) {
    permStato.onkeydown = function (e) {
      if (e.key == '-' || e.key == '.' || e.key == ',') {
        return false;
      }
    }
    permStato.onpaste = function (e) {
      e.preventDefault();
      return false;
    }
  }


  const accountExchange = (document.getElementById(inputId.Account_Exchange));

  if (accountExchange != null) {
    accountExchange.onkeydown = function (e) {
      if (e.key == ' ') {
        return false;
      }
    }
  }

  React.useEffect(() => { dispatch({ type: azioni.RESET, value: sala }) }, [sala]);
  React.useEffect(() => { setToggleAttivazioneSala(sala.attivazione); }, [sala]);

  function handle_change() {

    dispatch({ type: azioni.FORM_CHANGE });
  };

  function handle_blur(campo: azioni) {
    dispatch({ type: campo });
  }

  function checkNotValidInsertFields() {
    if (!state.salaForm.nome) setNotValidNome(true);
    if (!state.salaForm.accountExchange?.trim()) setIsAccountExchangeValid(false);
  }

  function checkNotValidUpdateFields() {
    if (sala.nome) setNotValidNome(true);
    if (!sala.accountExchange?.trim()) setIsAccountExchangeValid(false);
    if (sala.capienza as number > -1) setNotValidCapienza(true);
  }



  async function handle_submit() {

    if (state.salaForm.nome?.trim() && isAccountExchangeValid && state.salaForm.accountExchange?.trim()) {
      setLoading(true);
      let risultati: RisultatoOperazione[] = [];

      let success: boolean = await SalaService.AddSala(state.salaForm);
      console.log(success)
      let msg: string = (success) ? "sala aggiunta" : "sala non aggiunta";

      risultati.push(new RisultatoOperazione(success, msg));

      dispatch({ type: azioni.SUBMIT, value: risultati });
      (!success) ? handleError() : handleClose(); confirmCreate();
    } else {
      handleNotValidInsert();
    }
    //confirmFunction();
  }


  async function handle_update() {

    if (state.salaForm.nome?.trim() && isAccountExchangeValid && state.salaForm.capienza as number > -1) {
      console.log('sono in modifica')
      setLoading(true);
      let _sala = raccogliModifiche(sala);

      let risultati: RisultatoOperazione[] = [];

      let success: boolean = await SalaService.UpdateSala(_sala);
      console.log(success)
      let msg: string = (success) ? "sala modificata" : "sala non modificata";
      risultati.push(new RisultatoOperazione(success, msg));

      dispatch({ type: azioni.SUBMIT, value: risultati });
      (!success) ? handleError() : handleClose(); confirmUpdate();
    }
    else {
      handleNotValidUpdate();
    }
    // confirmFunction('update');
  }

  function getToastMsg() {
    let msg: string = '';

    if (sala.idSala)
      msg = toastSuccess ? Dictionary.FormSala.updateForm_title : 'Operazione Fallita.'
    else
      msg = toastSuccess ? Dictionary.FormSala.addForm_title : 'Operazione Fallita.'

    return msg;
  }


  function isExchangeValid() {
    const email = (document.getElementById(inputId.Account_Exchange) as HTMLInputElement).value;
    setIsAccountExchangeValid(Expressions.EMAIL_FORMAT_REGEX.test(email));
  }


  return (
    <div>
      <Dialog className="SalaDialog" open={_open} onClose={handleAbort}>
        <DialogTitle>{sala.nome ? Dictionary.FormSala.updateForm_title : Dictionary.FormSala.addForm_title}</DialogTitle>
        <LoadingBar show={loading} />
        <DialogContent className='salaDialogContent' dividers>

          <TextField
            error={(!state.salaForm.nome?.trim() && state.nome_blur) ? true : false}
            color='success'
            autoFocus
            margin="dense"
            id={inputId.Nome}
            label="Nome"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={sala.nome ? sala.nome : ''}
            onChange={() => { handle_change() }}
            onBlur={() => { handle_blur(azioni.BLUR_NOME) }} />

          <TextField
            error={(!state.salaForm.accountExchange?.trim() && state.acc_blur || !isAccountExchangeValid) ? true : false}

            color='success' margin="dense" defaultValue={sala.nome ? sala.accountExchange : ''} className="inputAccount" id="accountExchange" label="Account Exchange" type='email' fullWidth variant="standard"
            onChange={() => { handle_change(); isExchangeValid(); }}
            onBlur={() => { handle_blur(azioni.BLUR_ACCOUNT) }}
            onKeyDown={() => { isExchangeValid() }} />


          <TextField
            inputProps={{
              maxLength: 255
            }}
            color='success' margin="dense" defaultValue={sala.nome ? sala.descrizione : ''} id="descrizione" label="Descrizione" type="text" fullWidth variant="standard"
            onChange={() => { handle_change() }}
            onBlur={() => { handle_blur(azioni.BLUR_DES) }}
          />
          <ToggleButtonGroup
            color="success"
            exclusive


          >
            <InputLabel className={sala.nome ? 'inputAtt' : 'inputHidden'} color="success" htmlFor="Attivazione">Attivazione Sala</InputLabel> <Switch className={sala.nome ? 'toggleAtt' : 'inputHidden'} id="Attivazione" color="success"
              defaultChecked={sala.nome ? sala.attivazione as boolean : true}
              onClick={() => { handle_change(); setToggleAttivazioneSala(!toggleAttivazioneSala); }}
              onBlur={() => { handle_blur(azioni.BLUR_ATTIVAZIONE) }} />
          </ToggleButtonGroup>
          <TextField
            disabled={toggleAttivazioneSala == false ? false : true}

            error={(!state.salaForm.messaggioNonDisponibile?.trim() && state.messaggioNonDisp_blur) ? true : false}
            color='success' margin="dense" defaultValue={sala.nome ? sala.messaggioNonDisponibile : ''} className={sala.nome ? 'inputMessaggioNonDisp' : 'inputHidden'} id="MessaggioNonDisponibile" label="Messaggio Non Disponibile" type="text" fullWidth variant="standard"
            onChange={() => { handle_change() }}
            onBlur={() => { handle_blur(azioni.BLUR_MESSAGGIO_NON_DISP) }}
          />
          <TextField
            inputProps={{ min: 0 }}
            disabled={toggleAttivazioneSala == false ? true : false}
            error={(!state.salaForm.capienza && state.cap_blur || state.salaForm.capienza as number < 0) ? true : false}
            color='success' margin="dense" defaultValue={sala.nome ? sala.capienza : ''} className={sala.nome ? "inputCapienza" : "inputHidden"} id="capienza" label="Capienza" type="number" variant="standard"
            onChange={() => { handle_change() }}
            onBlur={() => { handle_blur(azioni.BLUR_CAP) }} />
          <br />
          <Tooltip color='success' arrow TransitionComponent={Fade} placement='right' hidden={configurazioneIB == true ? true : false} disableHoverListener={configurazioneIB == true ? true : false} title="Funzionalità disattivata.">
            <ToggleButtonGroup
              color="success"
              exclusive
            >

              <InputLabel className={sala.nome ? 'inputIB' : 'inputHidden'} color="success" htmlFor="InstantBooking">Instant Booking</InputLabel>
              <Switch disabled={configurazioneIB == false ? true : toggleAttivazioneSala == false ? true : false} className={sala.nome ? '' : 'inputHidden'} id="InstantBooking" color="success"
                defaultChecked={configurazioneIB == false ? false : sala.nome ? sala.instantBooking as boolean : true}
                onClick={() => { handle_change() }}
                onBlur={() => { handle_blur(azioni.BLUR_INSTANT_BOOKING) }}
              />

            </ToggleButtonGroup></Tooltip>
          {sala.nome &&<br />}
          <Tooltip color='success' arrow TransitionComponent={Fade} placement='right' hidden={configurazioneCheckIn == true ? true : false} disableHoverListener={configurazioneCheckIn == true ? true : false} title="Funzionalità disattivata.">
            <ToggleButtonGroup
              color="success"
              exclusive
            >

              <InputLabel className={sala.nome ? 'inputCheckIn' : 'inputHidden'} color="success" htmlFor="inputCheckIn">Check In</InputLabel>
              <Switch disabled={configurazioneCheckIn == false ? true : toggleAttivazioneSala == false ? true : false} className={sala.nome ? '' : 'inputHidden'} id="CheckIn" color="success"
                defaultChecked={configurazioneCheckIn == false ? false : sala.nome ? sala.checkIn as boolean : true}
                onClick={() => { handle_change() }}
                onBlur={() => { handle_blur(azioni.BLUR_CHECK_IN) }}
              />

            </ToggleButtonGroup></Tooltip>
          {sala.nome && <br />}
          {sala.nome && <InputLabel className='inputCheckIn' color="success" >Timing</InputLabel>}
          <TextField
            id={inputId.Perm_Stato}
            inputProps={{ min: 0 }}
            disabled={toggleAttivazioneSala == false ? true : false}
            error={(!state.salaForm.permanenzaStato && state.permStato_blur || state.salaForm.permanenzaStato as number < 0) ? true : false}
            color='success' margin="dense" defaultValue={sala.nome ? sala.permanenzaStato : '0'} className={sala.nome ? "inputPermStato" : "inputHidden"} label="Home" type="number" variant="standard"
            onChange={() => { handle_change() }}
            onBlur={() => { handle_blur(azioni.BLUR_PERM_STATO) }} />

          <TextField
            inputProps={{ min: 0 }}
            id={inputId.Perm_Elenco}
            disabled={toggleAttivazioneSala == false ? true : false}
            error={(!state.salaForm.permanenzaElenco && state.permElenco_blur || state.salaForm.permanenzaElenco as number < 0) ? true : false}
            color='success' margin="dense" defaultValue={sala.nome ? sala.permanenzaElenco : '0'} className={sala.nome ? "inputPermElenco" : "inputHidden"} label="Elenco prenotazioni" type="number" variant="standard"
            onChange={() => { handle_change() }}
            onBlur={() => { handle_blur(azioni.BLUR_PERM_ELENCO) }} />

{config.signageBook == true && <TextField
            id={inputId.Perm_Spot}
            inputProps={{ min: 0 }}
            disabled={toggleAttivazioneSala == false ? true : false}
            error={(!state.salaForm.permanenzaSpot && state.permSpot_blur || state.salaForm.permanenzaSpot as number < 0) ? true : false}
            color='success' margin="dense" defaultValue={sala.nome ? sala.permanenzaSpot : '0'} className={sala.nome ? "inputPermSpot" : "inputHidden"} label="Spot (per img)" type="number" variant="standard"
            onChange={() => { handle_change() }}
            onBlur={() => { handle_blur(azioni.BLUR_PERM_SPOT) }} />}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleAbort} color="success">Annulla</Button>
          {sala.nome && <Button variant="contained" color="success"
            onClick={() => { handle_update(); }} >
            Salva
          </Button>}
          {!sala.nome && <Button variant="contained" color="success"
            onClick={(e) => { handle_submit() }} >
            Salva
          </Button>}
        </DialogActions>
      </Dialog>
      {/*TOAST CONFERMA*/}
      <GenericToast _open={showToast} _vertical='bottom' _horizontal="right"
        _severity={toastSuccess ? 'success' : 'error'}
        _message={getToastMsg()}
        mostraModal={setShowToast}
        _autoClose={3000} />
    </div>
  );
}
export default FormDialogSala;

function raccogliInput(stato: statoForm) {
  stato.salaForm.nome = (document.getElementById(inputId.Nome) as HTMLInputElement).value;
  stato.salaForm.descrizione = (document.getElementById(inputId.Descrizione) as HTMLInputElement).value;
  stato.salaForm.capienza = (document.getElementById(inputId.Capienza) as HTMLInputElement).value || '0';
  stato.salaForm.messaggioNonDisponibile = (document.getElementById(inputId.MessaggioNonDisp) as HTMLInputElement).value || 'Sala al momento non disponibile';
  stato.salaForm.instantBooking = (document.getElementById(inputId.InstantBooking) as HTMLInputElement).checked;
  stato.salaForm.attivazione = (document.getElementById(inputId.Attivazione) as HTMLInputElement).checked;
  stato.salaForm.accountExchange = (document.getElementById(inputId.Account_Exchange) as HTMLInputElement).value;
  stato.salaForm.checkIn = (document.getElementById(inputId.Check_In) as HTMLInputElement).checked;
  stato.salaForm.permanenzaElenco = (document.getElementById(inputId.Perm_Elenco) as HTMLInputElement).value || '0';
  stato.salaForm.permanenzaSpot = document.getElementById(inputId.Perm_Spot) as HTMLInputElement ? (document.getElementById(inputId.Perm_Spot) as HTMLInputElement).value : '10';
  stato.salaForm.permanenzaStato = (document.getElementById(inputId.Perm_Stato) as HTMLInputElement).value || '0';

  return stato;
}

function raccogliModifiche(sala: Sala) {
  sala.nome = (document.getElementById(inputId.Nome) as HTMLInputElement).value;
  sala.descrizione = (document.getElementById(inputId.Descrizione) as HTMLInputElement).value;
  sala.capienza = (document.getElementById(inputId.Capienza) as HTMLInputElement).value;
  sala.instantBooking = (document.getElementById(inputId.InstantBooking) as HTMLInputElement).checked;
  sala.attivazione = (document.getElementById(inputId.Attivazione) as HTMLInputElement).checked;
  sala.accountExchange = (document.getElementById(inputId.Account_Exchange) as HTMLInputElement).value;
  sala.messaggioNonDisponibile = (document.getElementById(inputId.MessaggioNonDisp) as HTMLInputElement).value;
  sala.checkIn = (document.getElementById(inputId.Check_In) as HTMLInputElement).checked;
  sala.permanenzaElenco = (document.getElementById(inputId.Perm_Elenco) as HTMLInputElement).value;
  sala.permanenzaSpot = document.getElementById(inputId.Perm_Spot) as HTMLInputElement ? (document.getElementById(inputId.Perm_Spot) as HTMLInputElement).value : '0';
  sala.permanenzaStato = (document.getElementById(inputId.Perm_Stato) as HTMLInputElement).value;

  return sala;
}

function convalidaForm(stato: statoForm) {

  if (stato.salaForm.nome) {
    stato.formvalid = true;
  } else stato.formvalid = false;

  return stato;
}