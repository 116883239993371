import React from "react";
import GenericTable from "./GenericTable";
import { Box, Paper } from "@mui/material";
import { SortOrder } from "./GenericTableHeader";
import SearchAppBar from "../../Layouts/Main/SearchAppBar";
import { QueryModel } from "../../Services/Utils/QueryModel";
import { tableObjects } from "../../Services/Enums/ObjectsEnum";
import TemporaryDrawerLeft from "../../Layouts/Main/TemporaryDrawerLeft";
import "./genTablePage.css";
import { Config } from "../../Models/Config";

class TablePageProps {
  tipo: tableObjects = tableObjects.Utente;
  titolo: string = '';
  oggetti: object[] = [];
  loading: boolean = false;
  ricaricaOggetti: Function = () => { };
  count: number = 0;

  ricerca: Function = () => { }

  sortBy?: string = '';
  sortDirection?: SortOrder = 'asc';
  onSort?: Function = () => { };
  onAdd?: Function = () => { };
  onUpdate?: Function = () => { };
  onDelete?: Function = () => { };

  queryModel: QueryModel = new QueryModel();
  changePageSize: Function = () => { };
  goToPage: Function = () => { };
  currentPage:number=0;
  tipoElementi: string = '';
  config: Config = new Config();
}

const GenericEntityPage: React.FC<TablePageProps> = ({currentPage, count, tipo, titolo, oggetti, ricaricaOggetti, ricerca, loading, onAdd, onUpdate, onDelete, onSort, sortBy, sortDirection, queryModel, changePageSize, goToPage, tipoElementi, config }) => {

  return <>

    <TemporaryDrawerLeft _titoloSezione={titolo}>

      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2, height: '100%' }}
      >

        <div className="searchBar" role="presentation">
          <SearchAppBar
            ricerca={ricerca}
            onAdd={onAdd}
          />
        </div>
        <Paper className="paperGridSale">
          <GenericTable
            tipo={tipo}
            oggetti={oggetti}
            ricaricaElementi={ricaricaOggetti}
            loading={loading}
            onSort={onSort}
            sortBy={sortBy}
            onUpdate={onUpdate}
            onDelete={onDelete}
            sortDirection={sortDirection}
            queryModel={queryModel}
            changePageSize={changePageSize}
            goToPage={goToPage}
            count={count} 
            currentPage={currentPage}
            tipoElementi= {tipoElementi}
            config= {config} />
        </Paper>

      </Box>
    </TemporaryDrawerLeft>

  </>
}

export default GenericEntityPage;
