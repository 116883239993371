
import { Button, FormControl, Grid, Input, InputLabel } from '@mui/material';
import Dictionary from '../../Config/dictionary';
import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../Context/SessionContext';
import { AppSession, SessionManager } from '../../Services/SessionManager';
import { LoginActions, LoginReducer, LoginState } from '../../Services/Reducers/LoginReducer';
import '../../Pages/Login/Login.css';
import LoadingBar from '../../Layouts/LoadingBar';
import teletabberlogo from '../../Assets/Images/teletabber_logo.png';

function noSpace(e: React.KeyboardEvent) {
  let key = e.key;
  if (key === ' ') e.preventDefault();
}

const Login: React.FC = () => {
  const [state, dispatch] = useReducer(LoginReducer, new LoginState());
  const [loading, setLoading] = useState(false);
  const { session, setSession } = useSession();
  const navigate = useNavigate();

  useEffect(() => { setSession(SessionManager.GetSessionFromStorage()) }, []);

  useEffect(() => {
    if (SessionManager.isValid(session)) {
      navigate(Dictionary.Routes.dashboard_route, { replace: true });
    }
  }, [session]);

  async function handleSubmit() {
    setLoading(true);

    if (state.emailValid && state.passwordValid) {

      let newSession: AppSession = await SessionManager.LoginWithCredentials(state.loginModel);

      if (SessionManager.isValid(newSession)) {

        setSession(newSession);

      } else dispatch({ type: LoginActions.LOGIN_FAIL });

    } else { dispatch({ type: LoginActions.SUBMIT }); }

    setLoading(false);
  }


  return (
    <>

      <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid xs={12} sm={12} md={6} lg={6}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className='boxSxLogin'>

            <div className='formContainer'>

              <img className='teletab-logo' src={teletabberlogo}/>

              {/*<div className='login_title'>{Dictionary.Login.loginFor_title}</div>}m*/}
              <div className='login_title'>LOGIN</div>

             {/*  <p className='login_subtitle'>{Dictionary.Login.loginForm_subtitle}</p>*/}

              <FormControl className="formControlUsername" sx={{ m: 1, width: '22rem' }} variant="standard">
                <InputLabel color='success' htmlFor="input-userName">UserName</InputLabel>
                <Input
                  error={state.submitted && state.emailErrMsg.length > 0}
                  id="input-userName"
                  onKeyDown={(e) => { noSpace(e) }}
                  onChange={(e) => { dispatch({ type: LoginActions.EMAIL_CHANGE, value: e.target.value }) }}
                  color="success" 
                />
                <div className='err_campo'>
                  {state.submitted && state.emailErrMsg}
                </div>

              </FormControl>
              <FormControl className="formControlPassword" sx={{ m: 1, width: '22rem' }} variant="standard">
                <InputLabel color='success'  htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                  error={state.submitted && state.passwordErrMsg.length > 0}
                  id="standard-adornment-password"
                  type={state.showPassword ? 'text' : 'password'}
                  onKeyDown={(e) => { noSpace(e) }}
                  onChange={(e) => { dispatch({ type: LoginActions.PASSWORD_CHANGE, value: e.target.value }) }}
                  color="success"
                />

                <div className='err_campo'>
                  {state.submitted && state.passwordErrMsg}
                </div>
              </FormControl>



              <Button
                type='submit'
                sx={{ m: 2, }}
                className="btnpad buttonLogin" color={'success'} variant="contained">
                Entra
              </Button>

              <LoadingBar width='5rem' show={loading} />
              <div className='err_accesso'>
                {state.loginfailed && <span>accesso non riuscito</span>}
              </div>
              <div className='bot-spacer'></div>
            </div>
          </form>
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={6}>
          <div className='boxDxLogin'>
            <div className='bslogo_title'>{Dictionary.Login.loginSide_title}</div>
            <p>{Dictionary.Login.loginSide_subtitle} </p><p>{Dictionary.Login.loginSide_lastTitle}</p>
          </div>
        </Grid>

      </Grid>
    </>
  )
}

export default React.memo(Login);