import { SessionManager } from "../SessionManager";

export abstract class RequestConfig {


    public static queryparams(parametri?: object) {

        let options = {
            headers: this.setHeaders(),
            params: parametri || {}
        };
        return options;
    }

    public static deletedata(data?: object) {

        let options = {
            headers: this.setHeaders(),
            data: data || {}
        };
        return options;
    }

    private static setHeaders() {
        
        return {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Expose-Headers": "totalcount",
            "Authorization": "Bearer " + this.getToken(),
        };
    }

    private static getToken(): string {
        let session = SessionManager.GetSessionFromStorage();
        return (session.accessToken || '');
    }
}
