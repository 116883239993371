import axios from "axios";
import { Endpoints } from "../Config/Endpoints";
import { RequestConfig } from "./Utils/RequestConfig";

export default class ImpostazioniService { 

    public static async GetLastDayCount() {

        let count=0;
        await axios.get(Endpoints.Prenotazione.get_LastDay_Count, RequestConfig.queryparams({}))
      
            .then((response: any) => {      
                count= response.data;             
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
           
        return count;
    }

    public static async GetLastWeekCount() {

        let count=0;
        await axios.get(Endpoints.Prenotazione.get_LastWeek_Count, RequestConfig.queryparams({}))
      
            .then((response: any) => {      
                count= response.data;             
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return count;
    }

    public static async GetLastMonthCount() {

        let count=0;
        await axios.get(Endpoints.Prenotazione.get_LastMonth_Count, RequestConfig.queryparams({}))
      
            .then((response: any) => {      
                count= response.data;             
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return count;
    }

    public static async GetDeletedCount() {

        let count=0;
        await axios.get(Endpoints.Prenotazione.get_Deleted_Count, RequestConfig.queryparams({}))
      
            .then((response: any) => {      
                count= response.data;             
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return count;
    }
    public static async GetCountGroupBySala() {

        let count;
        await axios.get(Endpoints.Prenotazione.get_Count_GroupBySala, RequestConfig.queryparams({}))
      
            .then((response: any) => {      
                count= response.data;   
                console.log(response.data)          
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return count;
    }
    public static async GetCountGroupByHour() {

        let count;
        await axios.get(Endpoints.Prenotazione.get_Count_GroupByHour, RequestConfig.queryparams({}))
      
            .then((response: any) => {      
                count= response.data;   
                console.log(response.data)          
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return count;
    }

    public static async GetChartByHourAndSala() {

        let count;
        await axios.get(Endpoints.Prenotazione.get_Chart_ByHourAndSala, RequestConfig.queryparams({}))
      
            .then(async (response: any) => {      
                count= response.data;   
                return await response.data;
                console.log(response.data);

            }, (error: any) => {
                console.log(error);
            });
        return count;
    }

}