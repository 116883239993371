import { Sala } from "./Sala";

export class Pannello {
    idPannello?:  number;
    nome?: string | undefined;
    fkSala?:string | number | undefined;
    nomeSala?:string;
    dataUltimoPolling?:Date;
    pollingInterval?: number;
    statoPannello?: boolean;
    
    constructor (id?: number,  nome?: string, fkSala?: string | number, nomeSala?: string, dataUltimoPolling?: Date, pollingInterval?: number, statoPannello?: boolean) {
            
       this.idPannello = id;
       this.nome= nome;
       this.fkSala=fkSala;     
       this.nomeSala=nomeSala;
       this.dataUltimoPolling = dataUltimoPolling;
       this.pollingInterval = pollingInterval;
       this.statoPannello = statoPannello;
    }
    
    public static costruttoreEsteso(
        idPannello: number ,
        nome: string | undefined ,
        fkSala: string | number | null,
        nomeSala: string | undefined,
        dataUltimoPolling: Date,
        pollingInterval: number,
        statoPannello: boolean) {

        let pannello = new Pannello();

        if (idPannello) pannello.idPannello= idPannello;
        if (nome) pannello.nome = nome;
        if (fkSala) pannello.fkSala = fkSala;
        if (nomeSala) pannello.nomeSala = nomeSala;
        if (dataUltimoPolling) pannello.dataUltimoPolling = dataUltimoPolling;
        if (pollingInterval) pannello.pollingInterval = pollingInterval;
        if (statoPannello) pannello.statoPannello = statoPannello;

        return pannello;
    }
   
}