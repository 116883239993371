
import { Box, Typography, Paper, Grid, Divider, } from '@mui/material';
import "../../Pages/Dashboard/Dashboard.css";
import TemporaryDrawerLeft from '../../Layouts/Main/TemporaryDrawerLeft';
import Dictionary from '../../Config/dictionary';
import { useSession } from '../../Context/SessionContext';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useEffect, useState } from 'react';
import PrenotazioneService from '../../Services/PrenotazioneService';
import SalaService from '../../Services/SalaService';


const Dashboard = () => {
  const { session, setSession } = useSession();
  const [colorList, setColorList] = useState<any>([]);
  const [lastDayCount, setLastDayCount] = useState(0);
  const [lastWeekCount, setLastWeekCount] = useState(0);
  const [lastMonthCount, setLastMonthCount] = useState(0);
  const [deletedCount, setDeletedCount] = useState(0);
  const [countBySala, setCountBySala] = useState([]);
  const [countByHour, setCountByHour] = useState([]);
  const [dizionarioSala, setDizionarioSala] = useState([]);
  const [sale, setSale] = useState([]);


  async function fetchCounters() {

    let sale = await SalaService.getNames();
    setSale(sale);

    let lastDay = await PrenotazioneService.GetLastDayCount();
    setLastDayCount(lastDay as number);

    let lastWeek = await PrenotazioneService.GetLastWeekCount();
    setLastWeekCount(lastWeek as number);

    let lastMonth = await PrenotazioneService.GetLastMonthCount();
    setLastMonthCount(lastMonth as number);

    let deleted = await PrenotazioneService.GetDeletedCount();
    setDeletedCount(deleted as number);

    let bySala = await PrenotazioneService.GetCountGroupBySala();
    setCountBySala(bySala!);

    let byHour = await PrenotazioneService.GetCountGroupByHour();
    setCountByHour(byHour!);

    let dizionarioSala = await PrenotazioneService.GetChartByHourAndSala();
    setDizionarioSala(dizionarioSala!);

  }

  useEffect(() => { fetchCounters(); }, []);

  function generateColorList() {
    var colorList: any[] = [];
    sale.forEach(sala => {

      var coloreRandom = getColorCode();
      colorList.push(coloreRandom);
    }

    )

    setColorList(colorList);
  }

  function getColorCode() {
    var makeColorCode = '0123456789ABCD';
    var code = '#';
    for (var count = 0; count < 6; count++) {
      code = code + makeColorCode[Math.floor(Math.random() * 14)];
    }
    return code;
  }

  useEffect(() => {

    generateColorList();

  }, [dizionarioSala])

  return (
    <>
      <TemporaryDrawerLeft _titoloSezione={'Dashboard: ' + session.utente.userName}>


        <Box

          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}
        >


          <Grid className="dashboard_container" container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={{ xs: 2, md: 0.5 }} columns={{ xs: 12, sm: 12, md: 12 }}>

            <Grid item xs={3} sm={3} md={3}>
              <Typography paragraph>
                <Paper className="dashboard_element" elevation={3} >
                  <Box component="h4" className="card_title">{Dictionary.Dashboard.last_day_box_title}</Box>
                  <Divider className='divider_chart'></Divider>
                  <Box component="h1" className="card_body">{lastDayCount}</Box>
                  <br />

                </Paper>
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <Typography paragraph>
                <Paper className="dashboard_element" elevation={3} >
                  <Box component="h4" className="card_title"> {Dictionary.Dashboard.last_week_box_title}</Box>
                  <Divider className='divider_chart'></Divider>
                  <Box component="h1" className="card_body">{lastWeekCount}</Box>

                </Paper>
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <Typography paragraph>
                <Paper className="dashboard_element" elevation={3} >
                  <Box component="h4" className="card_title">{Dictionary.Dashboard.last_month_box_title}</Box>
                  <Divider className='divider_chart'></Divider>
                  <Box component="h1" className="card_body">{lastMonthCount}</Box>

                </Paper>
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <Typography paragraph>
                <Paper className="dashboard_element" elevation={3} >
                  <Box component="h4" className="card_title"> {Dictionary.Dashboard.deleted_box_title}</Box>
                  <Divider className='divider_chart'></Divider>
                  <Box component="h1" className="card_body">{deletedCount}</Box>

                </Paper>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>

              <Paper className="dashboard_element_large" elevation={3}>
                <Box component="h2" className="card_title">{Dictionary.Dashboard.all_prenotation_by_hour}</Box>
                <Divider className='divider_chart'></Divider>
                <ResponsiveContainer width={'100%'} height={400}>
                  <AreaChart width={730} height={400} data={countByHour}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                      <linearGradient id="colorSale" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="var(--coloreSecondario)" stopOpacity={0.7} />
                        <stop offset="95%" stopColor="var(--coloreSecondario)" stopOpacity={0.7} />
                      </linearGradient>
                    </defs>
                    <XAxis interval={'preserveStart'} dataKey={"key"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip labelFormatter={value => { return 'FASCIA ORARIA: ' + value }} labelStyle={{ backgroundColor: "" }} />
                    <Legend verticalAlign="top" height={36} />
                    <Area type="monotone" name="Prenotazioni" dataKey="value" stroke="var(--coloreSecondario)" fillOpacity={1} fill="url(#colorSale)" />
                  </AreaChart>
                </ResponsiveContainer>
              </Paper>

            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Typography paragraph>

                <Paper className="dashboard_element_large" elevation={3}>
                  <Box component="h2" className="card_title">{Dictionary.Dashboard.all_prenotation_by_sala}</Box>
                  <Divider className='divider_chart'></Divider>
                  <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart width={750} height={250} data={countBySala} margin={{ top: 10, right: 30, left: -5, bottom: 0 }}>
                      <defs>
                        <linearGradient id="colorSale" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="var(--coloreSecondario)" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="var(--coloreSecondario)" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis height={90} angle={45}
                        dx={15}
                        dy={20}
                        minTickGap={-300} interval={0}
                        axisLine={false} fontWeight={400} fontSize={10} tickMargin={5} tickSize={15} dataKey="nomeSala" tickFormatter={(label : any) => label? `${(label as string).substring(0,18)}` : ''}/>
                      <YAxis />
                      <Tooltip labelFormatter={value => { return 'SALA: ' + value }} labelStyle={{ backgroundColor: "" }} />
                      <Legend verticalAlign="top" height={36} />
                      <Bar type='monotone' name='Prenotazioni' dataKey="prenotazioniTotali" stroke='var(--coloreSecondario)' fillOpacity={1} fill="url(#colorSale)" />
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography paragraph>

                <Paper className="dashboard_element_BySala" elevation={3}>
                  <Box component="h2" className="card_title">{Dictionary.Dashboard.all_prenotation_by_hourAndSala}</Box>
                  <Divider className='divider_chart'></Divider>
                  <ResponsiveContainer width={'100%'} height={520}  >
                    <LineChart width={730} height={400} data={dizionarioSala}
                      margin={{ top: 25, right: 30, left: 0, bottom: 0 }}>
                      <defs>
                        <linearGradient id="colorSale" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="var(--coloreSecondario)" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="var(--coloreSecondario)" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <XAxis interval={2} dataKey={"Orario"} />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip itemStyle={{ fontSize: "0.6rem", width:"10rem", maxHeight: "25px", lineHeight: '4px' }} labelFormatter={value => { return 'FASCIA ORARIA: ' + value }} viewBox={{ x: 40, y: 70, width: 700, height: 400 }} allowEscapeViewBox={{ x: true, y: true }} position={{ x: 0, y: 5 }} />
                      <Legend  width={150} wrapperStyle={{ top: 20, left: 20, borderRadius: 3, lineHeight: '15px', width: '10rem', height: '30rem',  whiteSpace: "nowrap", overflowX: "auto", overflowY:"auto", textOverflow: "ellipsis" }}  iconType={"square"} layout='vertical' verticalAlign="middle" align='left' />
                      {sale.map((sala, index) =>

                        <Line dot={false} type="monotone" name={sala} dataKey={sala} stroke={colorList[index]} fillOpacity={1} fill={colorList[index]} />

                      )}
                    </LineChart >
                  </ResponsiveContainer>
                </Paper>
              </Typography>
            </Grid>
          </Grid>
        </Box>

      </TemporaryDrawerLeft>
    </>

  )
}
export default Dashboard;