import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { faClock, faDashboard, faEllipsisVertical, faHome, faTachometerAlt, faTachometerAltAverage } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dictionary from '../../Config/dictionary';
import "./AccountMenu.css";
import { useNavigate } from 'react-router-dom';
import { AppSession, SessionManager } from '../../Services/SessionManager';
import { useSession } from '../../Context/SessionContext';
import { Utente } from '../../Models/Utente';
import { useEffect, useState } from 'react';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { session, setSession } = useSession();
  const [loggedout, setloggedout] = useState(false);
  const routes = Dictionary.Routes;

  useEffect(() => {
    navigate("/", { replace: false });
  }, [loggedout]);


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const navigate = useNavigate();

  function logout() {
    SessionManager.ClearSession();
    setSession(new AppSession());
    navigate("/");
  }


  return (
    <React.Fragment>
      <Box className="boxAccountMenu">
        <Tooltip title={Dictionary.AccountMenu.accountMenu_tooltipTitle}>
          <IconButton
            disableRipple={true}
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} size="lg" color='white' />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem onClick={() => { navigate(routes.dashboard_route, { replace: true }); }}>
          <FontAwesomeIcon icon={faTachometerAlt} className="userIcon" size="lg" /> {"Dashboard"}
        </MenuItem>
        {/*<Divider />*/}

        <MenuItem onClick={() => { navigate(routes.profilo, { replace: true }); }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUser} size="lg" className="gearIcon" />
          </ListItemIcon>
          {"Profilo"}
        </MenuItem>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faRightFromBracket} size="lg" className="logoutIcon" />
          </ListItemIcon>
          {Dictionary.AccountMenu.accountMenu_option3}
        </MenuItem>

      </Menu>
    </React.Fragment>
  );
}
