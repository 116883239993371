export class Sala {
    idSala?:  number;
    nome?: string | undefined;
    descrizione?:string | undefined;
    stato?:string | undefined;
    capienza? : string | number| undefined;
    accountExchange?: string | undefined;
    instantBooking? : boolean | string | undefined;
    attivazione? : boolean | string | undefined;
    permanenzaElenco?: string | number| undefined;
    permanenzaSpot?: string | number| undefined;
    permanenzaStato?: string | number| undefined;
    messaggioNonDisponibile?: string| undefined;
    checkIn? : boolean | string | undefined;

    
    constructor (idSala?: number,  nome?: string, descrizione?:string, stato? : string, capienza?: string | number,
        accountExchange? :string, instantBooking? : boolean, attivazione?: boolean, 
        permanenzaElenco?: string | number, permanenzaSpot?: string | number, permanenzaStato?: string | number, messaggioNonDisponibile? : string, checkIn? : boolean ) {
            
       this.idSala = idSala;
       this.nome= nome;
       this.descrizione=descrizione;
       this.stato = stato;
       this.capienza= capienza;
       this.accountExchange = accountExchange;
       this.instantBooking = instantBooking; 
       this.attivazione = attivazione;
       this.permanenzaElenco = permanenzaElenco;
       this.permanenzaSpot = permanenzaSpot;
       this.permanenzaStato = permanenzaStato;
       this.messaggioNonDisponibile = messaggioNonDisponibile;
       this.checkIn = checkIn;
    }
    
    public static costruttoreEsteso(
        idSala: number ,
        nome: string | undefined ,
        descrizione: string | null,
        stato: string | null,
        capienza:string | number| undefined,
        accountExchange: string | null,
        instantBooking: boolean | string | null,
        attivazione: boolean | string | null,
        permanenzaElenco: string | number| undefined,
        permanenzaSpot: string | number| undefined,
        permanenzaStato: string | number| undefined,
        messaggioNonDisponibile: string | undefined,
        checkIn: boolean | string | null,) {

        let sala = new Sala();

        if (idSala) sala.idSala= idSala;
        if (nome) sala.nome = nome;
        if (descrizione) sala.descrizione = descrizione;
        if (stato) sala.stato = stato;
        if (capienza) sala.capienza = capienza;
        if (accountExchange) sala.accountExchange = accountExchange;
        if (instantBooking) sala.instantBooking = instantBooking;
        if (attivazione) sala.attivazione = attivazione;
        if (permanenzaElenco) sala.permanenzaElenco = permanenzaElenco;
        if (permanenzaSpot) sala.permanenzaSpot = permanenzaSpot;
        if (permanenzaStato) sala.permanenzaStato = permanenzaStato;
        if (messaggioNonDisponibile) sala.messaggioNonDisponibile = messaggioNonDisponibile;
        if (checkIn) sala.checkIn = checkIn;

        return sala;
    }
   
}