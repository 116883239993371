import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingBar from '../LoadingBar';

type Props = {
  _open: boolean;
  _nomeSala?: string;
  mostraModal: Function;
  _titolo: string;
  messaggioConferma: string;
  confirmFunction: Function;
  loading?: boolean;
};

const GenericAlert: React.FC<Props> = ({ _open, _titolo, mostraModal, messaggioConferma, confirmFunction, loading }) => {
 
  const handleAbort = () => { mostraModal(false) };

  return (
    <div>
      <Dialog
        color="success"
        open={_open}
        onClose={handleAbort}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {_titolo}
        </DialogTitle>
          <LoadingBar show={loading || false} />
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div>
              {messaggioConferma}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='btnpad' onClick={handleAbort} color="success" >Annulla</Button>
          <Button  className='btnpad' variant="contained" color="success" onClick={()=>{confirmFunction()}} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GenericAlert;