import { SortOrder } from "../../Components/GenericTable/GenericTableHeader";


export class QueryModel {

    propertyName: string = '';
    queryString: string = '';
    sortBy: string = '';
    sortDirection: SortOrder = 'asc';
    pageSize: number = 10;
    pageNumber: number = 1;
    totalCount:number=0;

    public static readonly nextPage = (model: QueryModel) => {
        model.pageNumber += 1;
    }

    public static readonly prevPage = (model: QueryModel) => {
    
        if (model.pageNumber > 1)
            model.pageNumber -= 1;
    }

    public static readonly setPage = (model: QueryModel, pageSize: number, pageNumber?: number) => {
        model.pageSize = pageSize;
        model.pageNumber = pageNumber || 1;
    }

    public static readonly setSearch = (model: QueryModel, queryString: string, propertyName: string) => {
        model.propertyName = propertyName;
        model.queryString = queryString;
    }

    public static readonly setSort = (model: QueryModel, sortBy: string, sortDirection?: SortOrder) => {
        model.sortBy = sortBy;
        model.sortDirection = sortDirection || 'asc';
    }

}