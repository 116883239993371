import axios from "axios";
import { convertToObject } from "typescript";
import { Endpoints } from "../Config/Endpoints";
import { Config } from "../Models/Config";
import { ImpostazioniGenerali } from "../Models/ImpostazioniGenerali";
import { Pannello } from "../Models/Pannello";
import { SessionManager } from "./SessionManager";
import { RequestConfig } from "./Utils/RequestConfig";

export default class ImpostazioniService {
    public static DestrutturazioneImpostazioni(jsnProd: any) {
        return new ImpostazioniGenerali(
            jsnProd.risultato.id,
            jsnProd.risultato.instantBooking,
            jsnProd.risultato.passo,
            jsnProd.risultato.unlockRoom,
            jsnProd.risultato.checkIn)
    }



    public static async GetAllImpostazioni() {


        let impostazioni: Array<ImpostazioniGenerali> = [];
        await axios.get(Endpoints.ImpostazioniGen.get_Get, RequestConfig.queryparams({}))

            .then((response: any) => {

                console.log(response.data)
                impostazioni = response.data;

                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        SessionManager.RefreshSession();
        return impostazioni;
    }

    public static async GetPanelConfig(): Promise<Config> {


        let config = new Config();

        await axios.get(Endpoints.ImpostazioniGen.get_Get_Panel_Config)

            .then((response: any) => {

                console.log(response.data)
                config = response.data;

                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        SessionManager.RefreshSession();
        return config;
    }

    public static UpdateImpostazioni = async function (impostazioni: ImpostazioniGenerali, accessToken: string) {
        console.log(impostazioni)

        return await axios.put(Endpoints.ImpostazioniGen.put_Update, impostazioni, RequestConfig.queryparams({}))
            .then((response) => {
                console.log(response, "modifica impostazioni effettuata ");
                SessionManager.RefreshSession();
                return true;
            }, (error) => {

                console.log(error, "chiamata effettuata ma nessuna Impostazione modificata");
                return false;
            });
    }

}