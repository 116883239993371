
import { Utente } from '../../Models/Utente';
import './ProfiloUtente.css';

type CardInfoProp = {
    utente: Utente
}
const CardInfoProfilo: React.FC<CardInfoProp> = ({ utente }) => {

    return <>

        <div className='field'>
            <div className='greenLabel'>UserName</div>
            {utente.userName}
        </div>

        <div className='field'>
            <div className='greenLabel'>Ruolo</div>
            {utente.role}
        </div>

    </>

}

export default CardInfoProfilo;