import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountMenu from './AccountMenu';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faGear, faTableCellsLarge, faUserGroup, faTabletScreenButton, IconDefinition, faAddressCard} from '@fortawesome/free-solid-svg-icons'
import Dictionary from '../../Config/dictionary';
import { AdminOnlyContent } from '../../Components/AdminRestricted';
import "./TemporaryDrawerLeft.css";
import teletabberlogo from '../../Assets/Images/teletabber_logo.png';


const drawerWidth = Dictionary.SideBar.sidebar_width;

enum ElementiSideBar {
  //lista elementi da ins. nella sidebar
  SALE = 'Sale',
  PANNELLI = 'Pannelli',
  UTENTI = 'Utenti',
  CARDS = "Card",
  IMPOSTAZIONI = 'Impostazioni',
}

function getOptionIcon(option: string): IconDefinition {
  //restituisce icona fontAwesome associata all'elemento

  switch (option) {
    case ElementiSideBar.PANNELLI: return faTabletScreenButton;
    case ElementiSideBar.IMPOSTAZIONI: return faGear;
    case ElementiSideBar.UTENTI: return faUserGroup;
    case ElementiSideBar.SALE: return faTableCellsLarge;
    case ElementiSideBar.CARDS: return faAddressCard;
  }
  return faCube;
}

type Props = {
  _titoloSezione: string;
};

const TemporaryDrawerLeft: React.FC<Props> = ({ _titoloSezione, children }) => {

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const drawerItem = (option: string) => {
    //renderizza elemento della Sidebar con nome
    return <>

      <ListItem className="listItem" button onClick={() => { navigate('/' + option) }}>
        <ListItemIcon className='iconaSideBar'>
          <FontAwesomeIcon className={'icona' + option} color='white' icon={getOptionIcon(option)} />
        </ListItemIcon>
        <ListItemText className="elementiSidebar" primary={option} />
      </ListItem>
      <Divider className="divider_sidebar" />
    </>
  };

  const drawer = (
    <Drawer className='sidebar'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: "var(--coloreSecondario)",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <div className='teletab-container'>
        <img className='teletab_logo' src={teletabberlogo} />
      </div>


      <Divider className="divider_sidebar" />
      <List className='no-pad'>
        {Object.values(ElementiSideBar).map(option =>
          <div key={option}>
            {option === ElementiSideBar.UTENTI ? // controllo sezioni ristrette ad Admin
              <AdminOnlyContent>{drawerItem(option)}</AdminOnlyContent>
              :
              drawerItem(option)}
          </div >)}
      </List >
    </Drawer >
  );

  return (
    <Box className="boxSidebar">
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: {
            sm: `${drawerWidth}px`,
            height: '100%',
            backgroundColor: "white"
          },
        }}
      >

        <Toolbar className='appHeader'>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, }}
          >
            <MenuIcon />
          </IconButton>
          <Typography className="toolbar_title" variant="h6" noWrap component="div">
            {_titoloSezione}
          </Typography>
          <AccountMenu></AccountMenu>
        </Toolbar>

        <div className='innerContent'>{children}</div>

      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer

          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },

          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default TemporaryDrawerLeft;
