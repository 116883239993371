import axios from "axios";
import { Endpoints } from "../Config/Endpoints";
import { Utente } from "../Models/Utente";
import { SessionManager } from "./SessionManager";
import { QueryModel } from "./Utils/QueryModel";
import { RequestConfig } from "./Utils/RequestConfig";

export default class GestioneUtenteService {

  public static async getCount(): Promise<number> {

    return await axios.get(Endpoints.Utente.get_Count, RequestConfig.queryparams({}))
      .then((res) => {
        SessionManager.RefreshSession();
        return res.data;

      }, (error: any) => {
        console.log(error);
        return 0;
      });

  }

  public static async Exist(email: string): Promise<boolean> {

    return await axios.get(Endpoints.Utente.Exist, RequestConfig.queryparams({ email: email }))
      .then((res) => {
        SessionManager.RefreshSession();
        return res.data;
        console.log(res.data);

      }, (error: any) => {
        console.log(error);
        return false;
      });

  }


  public static async GetPaginated(model: QueryModel): Promise<pagedUtenti> {

    let pagedlist = new pagedUtenti();

    return await axios.post(Endpoints.Utente.post_paginatedSearch, model, RequestConfig.queryparams({}))
      .then((res) => {
        pagedlist.count = parseInt(res.headers.totalcount);
        pagedlist.currentpage = parseInt(res.headers.currentpage);

        for (let u of res.data) {
          pagedlist.lista.push({
            id: u.id,
            userName: u.userName,
            role: u.role,
            isLastAdmin: u.isLastAdmin
          });
        }

        SessionManager.RefreshSession();
        return pagedlist;

      }, (error: any) => {
        console.log(error);
        return pagedlist;
      });

  }


  public static async GetAlla(): Promise<Utente[]> {

    let lista: Utente[] = [];

    return await axios.get(Endpoints.Utente.get_All, RequestConfig.queryparams())
      .then((res) => {

        for (let u of res.data) {
          lista.push({
            id: u.id,
            userName: u.userName,
            role: u.role,
          });
        }

        SessionManager.RefreshSession();
        return lista;

      }, (error: any) => {
        console.log(error);
        return lista;
      });

  }

  public static async Create(utente: Utente, password: string) {

    let registerModel = {
      userName: utente.userName,
      password: password,
      role: utente.role
    }

    return await axios.post(Endpoints.Login.post_RegisterUser, registerModel, RequestConfig.queryparams({}))
      .then((res) => {
        console.log(res);
        SessionManager.RefreshSession();
        return true;

      }, (error: any) => {
        console.log(error);
        return false;
      });

  }

  public static async Update(utente: Utente) {

    return await axios.put(Endpoints.Utente.put_Update, utente, RequestConfig.queryparams({}))
      .then((res) => {

        console.log(res);
        SessionManager.RefreshSession();
        return true;

      }, (error: any) => {
        console.log(error);
        return false;
      });

  }

  public static async Delete(id: string) {

    return await axios.delete(Endpoints.Utente.delete + id, RequestConfig.deletedata({}))
      .then((res) => {

        SessionManager.RefreshSession();
        return true;

      }, (error: any) => {
        console.log(error);
        return false;
      });

  }

  public static async passwordChange(model: PasswordChangeModel) {

    return await axios.put(Endpoints.Login.put_changePassword, model, RequestConfig.queryparams({}))
      .then((res) => {

        SessionManager.RefreshSession();
        return true;

      }, (error: any) => {
        console.log(error);
        return false;
      });

  }

}

export class PasswordChangeModel {
  userId: string = '';
  currentPassword: string = '';
  newPassword: string = '';

  constructor(userId: string, currentPassword: string, newPassword: string) {
    this.userId = userId;
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }
}

export class pagedUtenti {
  count: number = 0;
  currentpage: number = 0;
  lista: Utente[] = [];
}