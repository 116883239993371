import React, { useEffect, useReducer, useState } from "react";
import { AdminRestrictedPage } from "../../Components/AdminRestricted";
import FormDialogSala from "../../Components/Sale/FormDialogSala";
import GenericEntityPage from "../../Components/GenericTable/GenericEntityPage";
import FormDialogUtenti from "../../Components/Utenti/FormDialogUtenti";
import Dictionary from "../../Config/dictionary";
import GenericAlert from "../../Layouts/Main/GenericAlert";
import GenericToast from "../../Layouts/Main/GenericToast";
import RisultatoOperazione from "../../Models/RisultatoOperazione";
import { Sala } from "../../Models/Sala";
import { tableObjects } from "../../Services/Enums/ObjectsEnum";
import GestioneUtenteService from "../../Services/GestioneUtenteService";
import { queryActions, queryReducer } from "../../Services/Reducers/QueryReducer";
import SalaService from "../../Services/SalaService";
import { QueryModel } from "../../Services/Utils/QueryModel";
import ImpostazioniService from "../../Services/ImpostazioniService";
import { ImpostazioniGenerali } from "../../Models/ImpostazioniGenerali";
import { Config } from "../../Models/Config";
import { SignageBook } from "../../Models/SignageBook";
import SignageService from "../../Services/SignageService";
import FormDialogSignage from "../../Components/Signage/FormDialogSignage";

const initState: SignageBook[] = [];

enum Dialogs {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  None = ''
}

const azioni = queryActions;


const GestioneSignage: React.FC = () => {

  const [listaSignage, setListaSignage] = useState(initState);
  const [listaSettings, setListaSettings] = useState(new ImpostazioniGenerali());
  const [query, dispatch] = useReducer(queryReducer, new QueryModel());
  const [config, setConfig] = useState(new Config());


  //indicatori caricamento tabella e dialog
  const [searching, setSearching] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [signageSelezionata, setSignageSelezionata] = useState(new SignageBook());
  const [count, setCount] = useState(0);
  const [current, setCurrent] = useState(0);
  const [sala, setSala] = useState(new Sala());
  


  //variabile controllo apertura Dialog
  const [dialog, setDialog] = useState(Dialogs.None);
  //variabile controllo apertura toast
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);
  const [openCreateAlert, setOpenCreateAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

   const idSala = sessionStorage.getItem("idSala");

  function openCreate() {
    setSignageSelezionata(new SignageBook());
    setDialog(Dialogs.Create);
  }

  function openUpdate(idSignageBook: number) {
    let signage: SignageBook = listaSignage.filter(signage => signage.idSignageBook === idSignageBook)[0];
    setSignageSelezionata(signage);
    setDialog(Dialogs.Create);

  }

  function openDelete(idSignageBook: number) {
    let signage: SignageBook = listaSignage.filter(signage => signage.idSignageBook === idSignageBook)[0];
    setSignageSelezionata(signage);
    setDialog(Dialogs.Delete);
  }

  async function caricaSignage(idSala: number) {
    setSearching(true);
    let result = await SignageService.GetPanelSignage(idSala);
    let lista: SignageBook[] = result;
    setSearching(false);
    setListaSignage(lista);
  }

  async function fetchSala(idSala: number) {
    setSearching(true);
    let result = await SalaService.getById(idSala);
    setSearching(false);
    setSala(result);
  }

  useEffect(()=> {fetchSala(idSala as unknown as number)},[idSala]);

  async function caricaSettings() {

    const res = await ImpostazioniService.GetAllImpostazioni();

    if (res.length != 0) {


      setListaSettings(await res[0] as ImpostazioniGenerali);

    } else {

      //oggetto settings di default con timer impostati a 0 e IB attivo per tutte le sale
      setListaSettings({
        instantBooking: true,
        passo: 0,
        checkInTimer: 0,
        configInstantBooking: true,
        checkIn: true,
        configCheckIn: true
      });
    }

  }

  async function caricaConfig() {

    const res = await ImpostazioniService.GetPanelConfig();

      setConfig(await res as Config);

  }


  async function handleDelete() {
    setLoadingDialog(true);
    await SignageService.RemoveSignage(signageSelezionata.idSignageBook);
    setDialog(Dialogs.None);
    caricaSignage(idSala as unknown as number);
    setLoadingDialog(false);
    setOpenDeleteAlert(true);
  }

  function handleUpdate() {
    setOpenUpdateAlert(true);
  }
  function handleCreate() {
    setOpenCreateAlert(true);
  }

  function handleNotValid() {
    setOpenErrorAlert(true);
  }

  //ricarica dati all'avvio e al cambiamento dei parametri di ricerca
  useEffect(() => { caricaSignage(idSala as unknown as number); caricaSettings(); caricaConfig();}, []);
  useEffect(() => { caricaSignage(idSala as unknown as number); caricaSettings(); caricaConfig();}, [query]);

  function PageSize(pagesize: number) {
    dispatch({ type: azioni.PageSize, value: pagesize });
  }
  function PageNumber(pageNumber: number) {
    dispatch({ type: azioni.GoToPage, value: pageNumber });
  }

  function SearchByName(input: string) {
    dispatch({ type: azioni.Search, value: { searchBy: 'Nome', searchString: input } });
  }

  function SortByProperty(property: string, direction: 'asc' | 'desc') {
    dispatch({ type: azioni.SortBy, value: { sortBy: property, direction: direction } });
  }

  async function getCount() {
    setCount(await SalaService.getCount());
  }


  return <>
  
      <GenericEntityPage //PAGINA
        tipo={tableObjects.Signage}
        titolo={"Signage Booking" + (sala.nome==undefined? "" : " - " + sala.nome) }
        oggetti={listaSignage}
        ricaricaOggetti={caricaSignage}
        loading={searching}
        ricerca={SearchByName}
        onAdd={openCreate}
        onSort={SortByProperty}
        onUpdate={openUpdate}
        onDelete={openDelete}
        sortBy={query.sortBy}
        sortDirection={query.sortDirection}
        queryModel={query}
        changePageSize={PageSize}
        goToPage={PageNumber}
        count={count}
        currentPage={current}
        tipoElementi = {"Signage"}
        config={config as Config}
      /> 


    {/*     FORMDIALOG CREATE / UPDATE     */}

    { <FormDialogSignage errorFunction={() => { handleNotValid() }}
      notvalidFunction={() => { handleNotValid() }}
      signage={signageSelezionata}
      confirmFunction={() => { caricaSignage(idSala as unknown as number); }}
      confirmUpdate={() => { handleUpdate() }}
      confirmCreate={() => { handleCreate() }}
      _open={dialog === Dialogs.Create}
      mostraModal={() => { setDialog(Dialogs.None) }}
      idSala={idSala as unknown as number}
    /> }

    {/*     ALERT ELIMINAZIONE      */}
    <GenericAlert confirmFunction={handleDelete}
      _open={dialog === Dialogs.Delete}
      _titolo={Dictionary.GridSignage.warning_delete_message}
      _nomeSala={signageSelezionata.idSignageBook as unknown as string}
      mostraModal={() => { setDialog(Dialogs.None) }}
      messaggioConferma={
        Dictionary.GridSignage.explanation_delete_warning + signageSelezionata.nomeFile 
      }
      loading={loadingDialog}
    />

    {/*     TOAST CONFERMA ELIMINAZIONE  */}
    <GenericToast _open={openDeleteAlert} _vertical='bottom' _horizontal="right" _severity='success'
      _message={Dictionary.GestioneSignage.delete_confirm_alert} mostraModal={setOpenDeleteAlert}
      _autoClose={3000} />

    {/*TOAST CONFERMA UPDATE*/}
    <GenericToast _open={openUpdateAlert} _vertical='bottom' _horizontal="right" _severity='success'
      _message={Dictionary.GestioneSignage.update_confirm_alert} mostraModal={setOpenUpdateAlert}
      _autoClose={3000} ></GenericToast>

    {/*TOAST CONFERMA CREATE*/}
    <GenericToast _open={openCreateAlert} _vertical='bottom' _horizontal="right" _severity='success'
      _message={Dictionary.GestioneSignage.create_confirm_alert} mostraModal={setOpenCreateAlert}
      _autoClose={3000} ></GenericToast>


    {/*TOAST ERRORE
        <GenericToast _open={openErrorAlert} _vertical='bottom' _horizontal="right" _severity='error'
          _message={"Operazione fallita."} mostraModal={setOpenErrorAlert}
          _autoClose={3000} ></GenericToast> */}
  </>

}

export default GestioneSignage;
