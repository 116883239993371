export class Config {
    logoSxPath?: string;
    logoDxPath?: string;
    instantBooking?: boolean;
    pollingInterval?: number;
    signageBook?: boolean;

    constructor(logoSxPath?: string, logoDxPath?: string, instantBooking?: boolean, pollingInterval?: number, signageBook?: boolean) {

        this.logoSxPath = logoSxPath;
        this.logoDxPath = logoDxPath;
        this.instantBooking = instantBooking;
        this.pollingInterval = pollingInterval;
        this.signageBook = signageBook;
    }

    public static costruttoreEsteso(
        logoSxPath: string,
        logoDxPath: string,
        instantBooking: boolean,
        pollingInterval: number,
        signageBook: boolean
       ) {

        let config = new Config();

        if (logoSxPath) config.logoSxPath = logoSxPath;
        if (logoDxPath) config.logoDxPath = logoDxPath;
        if (instantBooking) config.instantBooking = instantBooking;
        if (pollingInterval) config.pollingInterval = pollingInterval;
        if (signageBook) config.signageBook = signageBook;

        return config;
    } 

}