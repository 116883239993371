import React, { useEffect, useReducer, useState } from "react";
import { AdminRestrictedPage } from "../../Components/AdminRestricted";
import FormDialogPannello from "../../Components/Pannelli/FormDialogPannello";
import FormDialogSala from "../../Components/Sale/FormDialogSala";
import GenericEntityPage from "../../Components/GenericTable/GenericEntityPage";
import FormDialogUtenti from "../../Components/Utenti/FormDialogUtenti";
import Dictionary from "../../Config/dictionary";
import GenericAlert from "../../Layouts/Main/GenericAlert";
import GenericToast from "../../Layouts/Main/GenericToast";
import { Pannello } from "../../Models/Pannello";
import RisultatoOperazione from "../../Models/RisultatoOperazione";
import { Sala } from "../../Models/Sala";
import { tableObjects } from "../../Services/Enums/ObjectsEnum";
import GestioneUtenteService from "../../Services/GestioneUtenteService";
import PannelloService from "../../Services/PannelloService";
import { queryActions, queryReducer } from "../../Services/Reducers/QueryReducer";
import SalaService from "../../Services/SalaService";
import { QueryModel } from "../../Services/Utils/QueryModel";
import { Config } from "../../Models/Config";

const initState: Pannello[] = [];

enum Dialogs {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  None = ''
}

const azioni = queryActions;

const GestionePannelli: React.FC = () => {

  const [listaPannelli, setListaPannelli] = useState(initState);
  const [query, dispatch] = useReducer(queryReducer, new QueryModel());


  //indicatori caricamento tabella e dialog
  const [searching, setSearching] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [pannelloSelezionato, setPannelloSelezionato] = useState(new Pannello());
  const [nomeSala, setNomeSala] = React.useState('');
  const [salaAbbinata, setSalaAbbinata] = useState('');
  const [idSalaAbbinata, setIdSalaAbbinata] = useState(0);
  const [updatingIdSala, setUpdatingIdSala] = React.useState(0);
  const [updatingSala, setUpdatingSala] = React.useState('');
  const [count, setCount] = useState(0);
  const [current, setCurrent] = useState(0);



  //variabile controllo apertura Dialog
  const [dialog, setDialog] = useState(Dialogs.None);
  //variabile controllo apertura toast
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);
  const [openCreateAlert, setOpenCreateAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);


  /*
  async function handleDelete() {
    let ok = await GestioneUtenteService.Delete(idSelezionata);

    if (ok) setOpenDeleteAlert(ok);
    else setOpenErrorAlert(ok);

    setLoadingDel(false);
    setShowDeleteDialog(false);
    const res = await GestioneUtenteService.GetPaginated(new QueryModel());
    // setlistaUtenti(res);
  }
*/

  function openCreate() {
    setPannelloSelezionato(new Pannello());
    setDialog(Dialogs.Create);
  }

  function openUpdate(pannelloId: number) {
    let pannello: Pannello = listaPannelli.filter(pannello => pannello.idPannello === pannelloId)[0];


    console.log("questo è la sala abbinata al pannello selezionato", pannello.nomeSala)

    setPannelloSelezionato(pannello);
    setUpdatingSala(pannello.nomeSala as string);
    setUpdatingIdSala(pannello.fkSala as number);

    setDialog(Dialogs.Create);

  }

  function openDelete(pannelloId: number) {
    let pannello: Pannello = listaPannelli.filter(pannello => pannello.idPannello === pannelloId)[0];
    setPannelloSelezionato(pannello);
    setDialog(Dialogs.Delete);
  }

  async function caricaPannelli(model: QueryModel) {
    setSearching(true);
    let result = await PannelloService.GetPaginated(model);
    let lista: Pannello[] = result.lista;
    setSearching(false);
    setListaPannelli(lista);
    console.log(result.count);
    setCount(result.count);
    setCurrent(result.currentPage);
  }

  async function handleDelete() {
    setLoadingDialog(true);
    await PannelloService.RemovePannello(pannelloSelezionato.idPannello);
    setDialog(Dialogs.None);
    setLoadingDialog(false);
    caricaPannelli(query);
    setOpenDeleteAlert(true);

  }

  function handleUpdate() {
    setOpenUpdateAlert(true);
  }
  function handleCreate() {
    setOpenCreateAlert(true);
  }

  function handleNotValid() {
    setOpenErrorAlert(true);
  }

  //ricarica dati all'avvio e al cambiamento dei parametri di ricerca
  useEffect(() => { caricaPannelli(query) }, []);
  useEffect(() => { caricaPannelli(query) }, [query])

  function PageSize(pagesize: number) {
    dispatch({ type: azioni.PageSize, value: pagesize });
  }
  function PageNumber(pageNumber: number) {
    dispatch({ type: azioni.GoToPage, value: pageNumber });
  }

  function SearchByName(input: string) {
    dispatch({ type: azioni.Search, value: { searchBy: 'Nome', searchString: input } });
  }

  function SortByProperty(property: string, direction: 'asc' | 'desc') {
    dispatch({ type: azioni.SortBy, value: { sortBy: property, direction: direction } });
  }

  async function getCount() {
    setCount(await PannelloService.getCount());
  }


  return <>

      <GenericEntityPage //PAGINA
        tipo={tableObjects.Pannello}
        titolo={Dictionary.GestionePannelli.gestionePannelli_sectionTitle}
        oggetti={listaPannelli}
        ricaricaOggetti={caricaPannelli}
        loading={searching}
        ricerca={SearchByName}
        onAdd={openCreate}
        onSort={SortByProperty}
        onUpdate={openUpdate}
        onDelete={openDelete}
        sortBy={query.sortBy}
        sortDirection={query.sortDirection}
        queryModel={query}
        changePageSize={PageSize}
        goToPage={PageNumber}
        count={count}
        currentPage={current}
        tipoElementi={"Pannelli"}
        config={new Config()}
      />

    {/*     FORMDIALOG CREATE / UPDATE     */}

    <FormDialogPannello
      ricaricaPannelli={() => caricaPannelli(query)}
      notvalidFunction={() => { }}
      errorFunction={() => { }}
      confirmFunction={() => caricaPannelli(query)}
      confirmUpdate={() => { handleUpdate() }}
      confirmCreate={() => { handleCreate() }}
      pannello={pannelloSelezionato as Pannello}
      idSala={pannelloSelezionato.fkSala as number}
      nomeSala={pannelloSelezionato.nomeSala as string}
      _open={dialog === Dialogs.Create}
      mostraModal={() => { setDialog(Dialogs.None) }}
    />

    {/*     ALERT ELIMINAZIONE      */}
    <GenericAlert confirmFunction={handleDelete}
      _open={dialog === Dialogs.Delete}
      _titolo={Dictionary.GridPannelli.warning_delete_message}
      _nomeSala={pannelloSelezionato.nomeSala}
      mostraModal={() => { setDialog(Dialogs.None) }}
      messaggioConferma={
        Dictionary.GridPannelli.explanation_delete_warning + pannelloSelezionato.nome?.toUpperCase()
      }
      loading={loadingDialog}
    />

    {/*     TOAST CONFERMA ELIMINAZIONE  */}
    <GenericToast _open={openDeleteAlert} _vertical='bottom' _horizontal="right" _severity='success'
      _message={Dictionary.GestionePannelli.delete_confirm_alert} mostraModal={setOpenDeleteAlert}
      _autoClose={3000} />

    {/*TOAST CONFERMA UPDATE*/}
    <GenericToast _open={openUpdateAlert} _vertical='bottom' _horizontal="right" _severity='success'
      _message={Dictionary.GestionePannelli.update_confirm_alert} mostraModal={setOpenUpdateAlert}
      _autoClose={3000} ></GenericToast>

    {/*TOAST CONFERMA CREATE*/}
    <GenericToast _open={openCreateAlert} _vertical='bottom' _horizontal="right" _severity='success'
      _message={Dictionary.GestionePannelli.create_confirm_alert} mostraModal={setOpenCreateAlert}
      _autoClose={3000} ></GenericToast>
  </>

}

export default GestionePannelli;
