import React, { useEffect, useReducer, useState } from "react";
import FormDialogCard from "../../Components/Card/FormDialogCard";
import GenericEntityPage from "../../Components/GenericTable/GenericEntityPage";
import Dictionary from "../../Config/dictionary";
import GenericAlert from "../../Layouts/Main/GenericAlert";
import GenericToast from "../../Layouts/Main/GenericToast";
import { Card } from "../../Models/Card";
import { Config } from "../../Models/Config";
import CardService from "../../Services/CardService";
import { tableObjects } from "../../Services/Enums/ObjectsEnum";
import { queryActions, queryReducer } from "../../Services/Reducers/QueryReducer";
import { QueryModel } from "../../Services/Utils/QueryModel";

const initState: Card[] = [];
const uitext = Dictionary.GestioneCard;

enum Dialogs {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  None = ''
}

const azioni = queryActions;
const defaultQuery: QueryModel = { sortBy: 'user', pageNumber: 1, pageSize: 10, propertyName: '', sortDirection: 'asc', queryString: '', totalCount: 0 };

const GestioneCard: React.FC = () => {
  const [listaCard, setListaCard] = useState(initState);
  const [query, dispatch] = useReducer(queryReducer, defaultQuery);
  //indicatori caricamento tabella e dialog
  const [searching, setSearching] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [cardSelez, setCardSelez] = useState(new Card());
  const [count, setCount] = useState(0);
  const [current, setCurrent] = useState(0);


  //variabile controllo apertura Dialog e toast
  const [dialog, setDialog] = useState(Dialogs.None);
  const [toast, setToast] = useState({ show: false, success: false });

  async function caricaCard(model: QueryModel) {
    setSearching(true);
    let totCount = 0;
    let result = await CardService.GetPaginated(model);
    setSearching(false);
    setListaCard(result.lista);
    setCount(result.count);
    setCurrent(result.currentPage);
    scrollToTop();
  }

  //ricarica dati all'avvio e al cambiamento dei parametri di ricerca
  useEffect(() => {
    caricaCard(query);
  }, []);
  useEffect(() => {
    caricaCard(query);
  }, [query]);


  function PageSize(pagesize: number) {
    dispatch({ type: azioni.PageSize, value: pagesize });
  }
  function PageNumber(pageNumber: number) {
    dispatch({ type: azioni.GoToPage, value: pageNumber });
  }

  function SearchByName(input: string) {
    dispatch({ type: azioni.Search, value: { searchBy: 'user', searchString: input } });
  }

  function SortByProperty(property: string, direction: 'asc' | 'desc') {
    dispatch({ type: azioni.SortBy, value: { sortBy: property, direction: direction } });
  }

  //------------dialogs/toast
  function openUpdate(cardId: number) {
    let card: Card = listaCard.filter(c => c.id === cardId)[0];
    setCardSelez(card);
    setDialog(Dialogs.Create);
  }

  function openDelete(cardId: number) {
    let card: Card = listaCard.filter(c => c.id === cardId)[0];
    setCardSelez(card);
    setDialog(Dialogs.Delete);
  }

  async function handleDelete() {
    setLoadingDialog(true);
    let ok = await CardService.Delete(cardSelez.id);

    setToast({ show: true, success: ok }) 
    setLoadingDialog(false);
    setDialog(Dialogs.None);
    caricaCard(query);
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return <>

    <GenericEntityPage //PAGINA

      tipo={tableObjects.Card}
      titolo={Dictionary.GestioneCard.gestioneCard_sectionTitle}
      oggetti={listaCard}
      ricaricaOggetti={() => { }}
      loading={searching}
      ricerca={SearchByName}
      onAdd={() => { setCardSelez(new Card()); setDialog(Dialogs.Create) }}
      onSort={SortByProperty}
      onUpdate={openUpdate}
      onDelete={openDelete}
      sortBy={query.sortBy}
      sortDirection={query.sortDirection}
      queryModel={query}
      changePageSize={PageSize}
      goToPage={PageNumber}
      count={count}
      currentPage={current}
      tipoElementi={"Card"}
      config={new Config()}
    />


   { <FormDialogCard card={cardSelez} //DIALOG CREA UTENTE
      errorFunction={() => { caricaCard(query) }}
      notvalidFunction={() => { }}
      confirmFunction={() => { caricaCard(query) }}
      _open={dialog === Dialogs.Create}
      mostraModal={() => { setDialog(Dialogs.None) }}
    />}

    <GenericAlert //DIALOG ELIMINAZIONE
      _open={dialog === Dialogs.Delete}
      _titolo={"ELIMINA CARD"}
      messaggioConferma={uitext.confermaElimina + cardSelez.user}
      _nomeSala={''}
      confirmFunction={handleDelete}
      mostraModal={() => { setDialog(Dialogs.None) }}
      loading={loadingDialog}
    />

    <GenericToast //TOAST CONFERMA DELETE
      _open={toast.show} _vertical='bottom' _horizontal="right"
      _severity={toast.success ? 'success' : 'error'}
      _message={toast.success ? Dictionary.GestioneCard.card_eliminato : Dictionary.GestioneCard.card_non_eliminato}
      mostraModal={() => { setToast({ ...toast, show: false }) }}
      _autoClose={3000} />

  </>

}

export default GestioneCard;
