import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { action_bool, action_string, action_utente, ReducerAction } from "../../Services/Reducers/ReducerAction";
import { Utente } from '../../Models/Utente';
import { Expressions } from '../../Services/Enums/Expressions';
import "./FormDialogUtenti.css";
import GestioneUtenteService from '../../Services/GestioneUtenteService';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { UserRoles } from '../../Services/Enums/UserRolesEnum';
import Dictionary from '../../Config/dictionary';
import LoadingBar from '../../Layouts/LoadingBar';
import GenericToast from '../../Layouts/Main/GenericToast';

const ui_text = Dictionary.GestioneUtenti;

type Props = {
  _open: boolean;
  mostraModal: Function;
  utente: Utente;
  confirmFunction: Function;
  errorFunction: Function;
  notvalidFunction: Function;
};

class statoForm {
  utenteForm: Utente = new Utente();
  password: string = '';

  emailValid: boolean = false;
  nameNotAvailableMsg: string = '';
  passwordValid: boolean = false;
  passConfirm: boolean = false;

  submitted: boolean = false;

  constructor(utente: Utente) {
    this.utenteForm = { ...utente };
    this.utenteForm.role = this.utenteForm.id ? this.utenteForm.role : UserRoles.USER;
    this.emailValid = Expressions.EMAIL_FORMAT_REGEX.test(utente.userName);
  }
}

enum inputId {
  email = 'input_email',
  role = 'input_role',
  password = 'input_password',
  passconfirm = 'password_confirm'
}

enum azioni {
  RESET = 'reset',
  SUBMIT = 'submit',
  EMAIL_CHANGE = 'emailchange',
  ROLE_CHANGE = 'rolechange',
  PASSWORD_CHANGE = 'passwordchange',
  EMAILNOTAVAILABLE = 'emailnotavailable',
}


function reducer(state: statoForm, action: ReducerAction) {
  let stato = { ...state };

  switch (action.type) {

    case azioni.RESET: {
      stato = new statoForm((action as action_utente).value);
      break;
    }

    case azioni.SUBMIT: {
      stato.submitted = true;
      break;
    }

    case azioni.EMAIL_CHANGE: {
      let input = (action as action_string).value.trim();
      stato.utenteForm.userName = input;
      stato.emailValid = Expressions.EMAIL_FORMAT_REGEX.test(input);
      break;
    }

    case azioni.ROLE_CHANGE: {
      let input = (action as action_string).value;
      console.log(input);
      stato.utenteForm.role = input;
      break;
    }
    case azioni.PASSWORD_CHANGE: {
      let pw: string = (document.getElementById(inputId.password) as HTMLInputElement).value.trim();
      let pwConf: string = (document.getElementById(inputId.passconfirm) as HTMLInputElement).value.trim();

      stato.password = pw;
      stato.passConfirm = (pw === pwConf);
      stato.passwordValid = pw.length > 8;
      break;
    }
    case azioni.EMAILNOTAVAILABLE: {
      let emailNotAvailable = (action as action_bool).value;
      if (emailNotAvailable) {
        stato.emailValid = false;
        stato.nameNotAvailableMsg = ui_text.NameNotAvailable;
      } else {
        stato.nameNotAvailableMsg = '';
      }

      break;
    }
  }

  return stato;
}

function noSpace(e: React.KeyboardEvent) {
  let key = e.key;
  if (key === ' ') e.preventDefault();
}

const FormDialogUtenti: React.FC<Props> = ({ _open, mostraModal, utente, confirmFunction, errorFunction, notvalidFunction }) => {
  const [state, dispatch] = React.useReducer(reducer, new statoForm(utente));
  const ruoli = [UserRoles.USER, UserRoles.ADMIN];
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastSuccess, setToastSuccess] = useState(false);

  const handleAbort = () => { mostraModal(false) };

  useEffect(() => { dispatch({ type: azioni.RESET, value: utente }) }, [utente]);
  useEffect(() => { checkEmailExist(); }, [state.utenteForm.userName]);

  async function checkEmailExist() {
    let notAvailable = false;
    if (state.emailValid && state.utenteForm.userName !== utente.userName) notAvailable = await GestioneUtenteService.Exist(state.utenteForm.userName);
    dispatch({ type: azioni.EMAILNOTAVAILABLE, value: notAvailable });
  }

  async function handle_submit() {

    if (!state.emailValid ||
      (!state.utenteForm.id && (!state.passwordValid || !state.passConfirm))) {

      // se campi form non corretti, segnala tentativo submit in dispatch per mostrare errori nel form
      dispatch({ type: azioni.SUBMIT });

    } else {
      //esegui request add/update utente
      setLoading(true);
      let success: boolean = (state.utenteForm.id) ?
        await GestioneUtenteService.Update(state.utenteForm)
        :
        await GestioneUtenteService.Create(state.utenteForm, state.password);

      // a seconda esito operazione richiama toast errore/conferma da funzione in props
      setLoading(false);
      mostraModal(false);

      if (success) confirmFunction();
      else errorFunction();

      setToastSuccess(success);
      setShowToast(true);
    }
  }

  function getToastMsg() {
    let msg: string = '';

    if (utente.id)
      msg = toastSuccess ? ui_text.utente_modificato : ui_text.utente_non_modificato
    else
      msg = toastSuccess ? ui_text.utente_creato : ui_text.utente_non_creato

    return msg;
  }

  return (<>
    <Dialog className="utenteDialog" open={_open} onClose={handleAbort}>
      <DialogTitle>
        {utente.id ? ui_text.modif_utente : ui_text.crea_utente}
      </DialogTitle>

      <LoadingBar show={loading} />

      <DialogContent className='utenteDialogContent' dividers>

        <TextField //EMAIL INPUT 
          type='email' className="inputAccount"
          color='success' margin="dense" fullWidth variant="standard"
          error={state.submitted && !state.emailValid}

          id={inputId.email}
          label="Username"
          defaultValue={utente.id ? utente.userName : ''}
          onKeyDown={(e) => { noSpace(e) }}
          onChange={(e) => { dispatch({ type: azioni.EMAIL_CHANGE, value: e.target.value }); }}
        />
        <div style={{ minHeight: '1rem', color: 'red', fontSize: '.7rem' }}>
          {(state.submitted && !state.emailValid && state.nameNotAvailableMsg) && state.nameNotAvailableMsg}
        </div>

        <FormControl fullWidth variant="standard" style={{ marginTop: '.8rem' }}>
          <InputLabel id="demo-simple-select-label" color='success'>Ruolo</InputLabel>
          <Tooltip placement={'bottom-start'}
            disableHoverListener={!utente.isLastAdmin}
            disableFocusListener disableTouchListener
            enterDelay={700}
            enterNextDelay={700}
            title={ui_text.non_elim_ultimo_admin}>

            <Select //ROLE INPUT
              disabled={utente.isLastAdmin}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={state.utenteForm.role}
              key={state.utenteForm.role}
              onChange={(e) => { dispatch({ type: azioni.ROLE_CHANGE, value: e.target.value }); }}
              color="success"
              label="Ruolo"
            >

              {
                ruoli.map(ruolo =>
                  <MenuItem key={"roleselect_" + ruolo} value={ruolo}>{ruolo}</MenuItem>
                )}
            </Select>
          </Tooltip>
        </FormControl>

        {!utente.id && /*PASSWORD INPUT*/ <>
          <TextField
            type='password'
            color='success' margin="dense" fullWidth variant="standard"
            error={state.submitted && !state.passwordValid}

            id={inputId.password}
            label="Password"
            onKeyDown={(e) => { noSpace(e) }}
            onChange={() => { dispatch({ type: azioni.PASSWORD_CHANGE }); }}
          />
          <TextField
            type='password'
            color='success' margin="dense" fullWidth variant="standard"
            error={state.submitted && !state.passConfirm}

            id={inputId.passconfirm}
            label="Conferma Password"
            onKeyDown={(e) => { noSpace(e) }}
            onChange={() => { dispatch({ type: azioni.PASSWORD_CHANGE }); }}
          />
        </>}

      </DialogContent>
      <DialogActions>
        <Button className='btnpad' onClick={handleAbort} color="success">Annulla</Button>

        <Button className='btnpad' variant="contained" color="success"
          onClick={handle_submit}>
          Salva
        </Button>

      </DialogActions>
    </Dialog>

    {/*TOAST CONFERMA*/}
    <GenericToast _open={showToast} _vertical='bottom' _horizontal="right"
      _severity={toastSuccess ? 'success' : 'error'}
      _message={getToastMsg()}
      mostraModal={setShowToast}
      _autoClose={3000} />
  </>
  );


}
export default FormDialogUtenti;
