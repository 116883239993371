declare global {
    interface Window {
        ROOT_DEV:string;
        ROOT_PROD:string;
    }
}

function isDevelopment() {
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
        return true
    } else {
        return false
    }
}

const BASE_DEV: string = window.ROOT_DEV;
const BASE_PROD: string = window.ROOT_PROD;

function getBaseUrl() {
    if (isDevelopment()) return BASE_DEV;
    else return BASE_PROD;
};

export class Endpoints {

    public static readonly Login = {
        post_NewAuthToken: getBaseUrl() + 'Login/Auth/',
        post_RegisterUser: getBaseUrl() + 'Login/Register',
        post_RefreshToken: getBaseUrl() + 'Login/extendtoken/',
        put_changePassword: getBaseUrl() + 'Login/changepassword/'
    }

    public static readonly ImpostazioniGen = {
        get_Get: getBaseUrl() + 'ImpostazioniGenerali/GetAllImpostazioni/',
        put_Update: getBaseUrl() + 'ImpostazioniGenerali/UpdateImpostazioni/',
        get_Get_Panel_Config: getBaseUrl() + 'ImpostazioniGenerali/GetPanelConfig'

    }

    public static readonly Sala = {
        get_All: getBaseUrl() + 'Sala/GetAllSala/',
        put_Update: getBaseUrl() + 'Sala/UpdateSala/',
        post_Add: getBaseUrl() + 'Sala/AddSala/',
        delete: getBaseUrl() + 'Sala/RemoveSala/',
        get_Count: getBaseUrl() + 'Sala/getCount/',
        get_Names: getBaseUrl() + 'Sala/GetAllSalaNames/',
        get_by_Id: getBaseUrl() + "Sala/GetById?idSala="

    }

    public static readonly Pannello = {
        get_All: getBaseUrl() + 'Pannello/GetAllPannello/',
        get_Pannello_with_Sale: getBaseUrl() + 'Pannello/GetPannelloWithSale/',
        put_Update: getBaseUrl() + 'Pannello/UpdatePannello/',
        post_Add: getBaseUrl() + 'Pannello/AddPannello/',
        delete: getBaseUrl() + 'Pannello/RemovePannello/',
        get_Count: getBaseUrl() + 'Pannello/getCount/',

    }

    public static readonly Utente = {
        post_paginatedSearch: getBaseUrl() + 'Utente/GetPaginated/',
        get_All: getBaseUrl() + 'Utente/TuttiGliUtenti/',
        get_ByEmail: getBaseUrl() + 'Utente/TrovaperEmail/',
        put_Update: getBaseUrl() + 'Utente/Modifica/',
        post_Add: getBaseUrl() + 'Utente/NuovoUtente/',
        delete: getBaseUrl() + 'Utente/Elimina/',
        get_Count: getBaseUrl() + 'Utente/getCount/',
        Exist: getBaseUrl() + "Utente/Exist/"
    }

    public static readonly Prenotazione = {
        get_LastDay_Count: getBaseUrl() + 'Prenotazione/getLastDayCount/',
        get_LastWeek_Count: getBaseUrl() + 'Prenotazione/getLastWeekCount/',
        get_LastMonth_Count: getBaseUrl() + 'Prenotazione/getLastMonthCount/',
        get_Deleted_Count: getBaseUrl() + 'Prenotazione/getDeletedCount/',
        get_Count_GroupBySala: getBaseUrl() + 'Prenotazione/getCountGroupBySala/',
        get_Count_GroupByHour: getBaseUrl() + 'Prenotazione/getCountGroupByHour/',
        get_Chart_ByHourAndSala: getBaseUrl() + 'Prenotazione/GetChartByHourAndSala/'
    }

    public static readonly SignageBook = {

        get_All: getBaseUrl() + 'Signage/GetAllSignage/',
        delete: getBaseUrl() + 'Signage/RemoveSignage/',
        post_Add: getBaseUrl() + 'Signage/AddSignage/',
        post_Get_Panel_Signage: getBaseUrl() + 'Signage/GetBySala?idSala='

    }

    public static readonly Card = {

        get_Paginated: getBaseUrl() + 'Card/GetByQuery/',
        get: getBaseUrl() + 'Card/Get/',
        post_Add: getBaseUrl() + 'Card/Create/',
        put_Update: getBaseUrl()+ 'Card/Update/',
        delete: getBaseUrl() + 'Card/Delete/',

    }

}