import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { tableObjects } from "../../Services/Enums/ObjectsEnum";
import "./genTablePage.css";

export type SortOrder = 'asc' | 'desc';

class HeadCell {
    disablePadding: boolean = false;
    name: string = '';
    label: string = '';
    numeric: boolean = false;
    sortable: boolean = true;

    constructor(name: string, type: string) {
        this.name = name;
        this.label = name;
        this.numeric = (type === 'number') ? true : false;
    }
}

//crea le HeadCells Appropriate secondo il tipo di oggetto
function compileCells(objectType: string): HeadCell[] {

    let cells: HeadCell[] = [];

    switch (objectType) {
        case tableObjects.Utente: {
            cells = [
                { name: 'userName', label: 'UserName', numeric: false, disablePadding: false, sortable: true },
                { name: 'role', label: 'Ruolo', numeric: false, disablePadding: false, sortable: true }
            ]
            break;
        }
        case tableObjects.Sala: {
            cells = [
                new HeadCell('Nome', 'string'),
                new HeadCell('Descrizione', 'string'),
/*                 new HeadCell('Stato', 'string'),
 */                new HeadCell('Capienza', 'number')]
            break;
        }
        case tableObjects.Pannello: {
            cells = [
                { name: 'Nome', label: 'Nome', numeric: false, disablePadding: false, sortable: true },
                { name: 'NomeSala', label: 'Sala', numeric: false, disablePadding: false, sortable: true },
                { name: 'StatoPannello', label: 'Stato Pannello', numeric: false, disablePadding: false, sortable: true }

            ]
            break;
        }
        case tableObjects.Signage: {
            cells = [
                { name: "Nome File", label: "Nome File", numeric: false, disablePadding: false, sortable: true},
               { name: 'File', label: 'Immagine', numeric: false, disablePadding: false, sortable: true }
            ]
            break;
        }
        case tableObjects.Card: {
            cells = [
                { name: 'user', label: 'User', numeric: false, disablePadding: false, sortable: true },
                { name: 'identifier', label: 'Codice', numeric: false, disablePadding: false, sortable: true }
            ]
            break;
        }
    }

    return cells;
}

interface EnhancedTableProps {
    tipo: tableObjects;
    oggetti: any[];
    onSort: Function;
    sortDirection: SortOrder;
    sortBy: string;
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({ tipo, oggetti, sortBy, sortDirection, onSort }) => {

    const cells = compileCells(tipo);

    function reverseSort() {
        return (sortDirection === 'desc') ? 'asc' : 'desc';
    }

    return (
        <TableHead>
            <TableRow className='head_table' sx={{ backgroundColor: "white" }}>

                {cells.map(headCell =>

                    <TableCell
                        key={'headcellMapKey_' + headCell.name}
                        align={'left'}
                        width={'auto'}
                        padding={'normal'}
                        className={"nome_Reduced"}
                    >
                        {headCell.sortable ? <TableSortLabel
                            active={sortBy === headCell.name}
                            direction={sortDirection}
                            onClick={() => { onSort(headCell.name, reverseSort()); }}
                        >
                            {headCell.label}
                        </TableSortLabel>
                        :
                        <span>{headCell.label}</span>}
                    </TableCell>
                )}

                <TableCell
                    key={'headcellMapKey_Azioni'}
                    align={'right'}
                    padding={'normal'}
                    width={'auto'}
                    className={"azioni_Reduced"}
                >Azioni
                </TableCell>

            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead