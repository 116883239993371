import { stepLabelClasses } from "@mui/material";
import axios from "axios";
import { Endpoints } from "../Config/Endpoints";
import { Sala } from "../Models/Sala";
import { SessionManager } from "./SessionManager";
import { QueryModel } from "./Utils/QueryModel";
import { RequestConfig } from "./Utils/RequestConfig";

export default class SalaService {
    public static DestrutturazioneSala(jsnProd: any) {
        return new Sala(
            jsnProd.risultato.id,
            jsnProd.risultato.nome,
            jsnProd.risultato.descrizione,
            jsnProd.risultato.stato,
            jsnProd.risultato.capienza,
            jsnProd.risultato.account,
            jsnProd.risultato.instant,
            jsnProd.risultato.attivazione)
    }

    public static async getCount(): Promise<number> {

        return await axios.get(Endpoints.Sala.get_Count, RequestConfig.queryparams({}))
            .then((res) => {
                console.log(res.data);
                SessionManager.RefreshSession();
                return res.data;

            }, (error: any) => {
                console.log(error);
                return 0;
            });

    }

    public static async getNames() {

        return await axios.get(Endpoints.Sala.get_Names, RequestConfig.queryparams({}))
            .then((res) => {
                console.log(res.data);
                SessionManager.RefreshSession();
                return res.data;

            }, (error: any) => {
                console.log(error);
                return 0;
            });

    }

    public static async getById(idSala: number) {

        return await axios.get(Endpoints.Sala.get_by_Id + idSala, RequestConfig.queryparams({}))
            .then((res) => {
                console.log(res.data);
                SessionManager.RefreshSession();
                return res.data;

            }, (error: any) => {
                console.log(error);
                return 0;
            });

    }

    public static async GetPaginated(model: QueryModel): Promise<pagedSala> {

        let pagedlist = new pagedSala();


        return await axios.post(Endpoints.Sala.get_All, model, RequestConfig.queryparams({}))
            .then((res) => {

                pagedlist.count = parseInt(res.headers.totalcount);
                pagedlist.currentPage =parseInt(res.headers.currentpage);

                for (let sala of res.data) {
                    pagedlist.lista.push({
                        idSala: sala.idSala,
                        nome: sala.nome,
                        descrizione: sala.descrizione,
                        stato: sala.stato,
                        messaggioNonDisponibile: sala.messaggioNonDisponibile,
                        capienza: sala.capienza,
                        accountExchange: sala.accountExchange,
                        instantBooking: sala.instantBooking,
                        attivazione: sala.attivazione,
                        checkIn: sala.checkIn,
                        permanenzaElenco: sala.permanenzaElenco,
                        permanenzaSpot: sala.permanenzaSpot,
                        permanenzaStato: sala.permanenzaStato
                    });
                }

                SessionManager.RefreshSession();
                return pagedlist;

            }, (error: any) => {
                console.log(error);
                return pagedlist;
            });

    }

    public static RemoveSala = async function (id: number | undefined) {

        let success: boolean = false;

        await axios.delete(Endpoints.Sala.delete + id, RequestConfig.deletedata())
            .then((response) => {
                console.log(response, "Sala eliminata");
                success = true;
            }, (error) => {
                console.log(error, "Sala non eliminata");
            });

        return success;
    }

    public static AddSala = async function (sala: Sala) {

        return await axios.post(Endpoints.Sala.post_Add, sala, RequestConfig.queryparams())
            .then((response) => {
                console.log(response, "aggiunta effettuata ");
                console.log(response.data.id)
                return true;
            }, (error) => {

                console.log(error, "chiamata effettuata ma nessuna sala aggiunta");
                return false;
            });
    }

    public static UpdateSala = async function (sala: Sala) {
        console.log(sala)

        return await axios.put(Endpoints.Sala.put_Update, sala, RequestConfig.queryparams())
            .then((response) => {
                console.log(response, "modifica effettuata ");
                return true;
            }, (error) => {

                console.log(error, "chiamata effettuata ma nessuna sala modificata");
                return false;
            });
    }
    /*
            public static UpdateAttivazione = async function (sala: Sala) {
                           
                return await axios.put("http://localhost:14278/api/Sala/UpdateAttivazione", sala)
                    .then((response) => {
                        console.log(response, "modifica attivazione effettuata ");
                        return true;
                    }, (error) => {
        
                        console.log(error, "chiamata effettuata ma nessuna sala modificata");
                        return false;
                    });
                    
            }
    */
}

export class pagedSala {
    count: number = 0;
    currentPage:number=0;
    lista: Sala[] = [];
}