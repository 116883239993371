import React, { useEffect, useReducer, useState } from "react";
import { AdminRestrictedPage } from "../../Components/AdminRestricted";
import GenericEntityPage from "../../Components/GenericTable/GenericEntityPage";
import FormDialogUtenti from "../../Components/Utenti/FormDialogUtenti";
import Dictionary from "../../Config/dictionary";
import GenericAlert from "../../Layouts/Main/GenericAlert";
import GenericToast from "../../Layouts/Main/GenericToast";
import { Config } from "../../Models/Config";
import { Utente } from "../../Models/Utente";
import { tableObjects } from "../../Services/Enums/ObjectsEnum";
import GestioneUtenteService from "../../Services/GestioneUtenteService";
import { queryActions, queryReducer } from "../../Services/Reducers/QueryReducer";
import { QueryModel } from "../../Services/Utils/QueryModel";

const initState: Utente[] = [];
const uitext = Dictionary.GestioneUtenti;

enum Dialogs {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
  None = ''
}

const azioni = queryActions;
const defaultQuery: QueryModel = { sortBy: 'UserName', pageNumber: 1, pageSize: 10, propertyName: '', sortDirection: 'asc', queryString: '', totalCount: 0 };

const GestioneUtenti: React.FC = () => {
  const [listaUtenti, setListaUtenti] = useState(initState);
  const [query, dispatch] = useReducer(queryReducer, defaultQuery);
  //indicatori caricamento tabella e dialog
  const [searching, setSearching] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [utenteSelez, setutenteSelez] = useState(new Utente());
  const [count, setCount] = useState(0);
  const [current, setCurrent] = useState(0);


  //variabile controllo apertura Dialog e toast
  const [dialog, setDialog] = useState(Dialogs.None);
  const [toast, setToast] = useState({ show: false, success: false });

  async function caricaUtenti(model: QueryModel) {
    setSearching(true);
    let totCount = 0;
    let result = await GestioneUtenteService.GetPaginated(model);
    setSearching(false);
    setListaUtenti(result.lista);
    setCount(result.count);
    setCurrent(result.currentpage);
  }

  //ricarica dati all'avvio e al cambiamento dei parametri di ricerca
  useEffect(() => {
    caricaUtenti(query);
  }, []);
  useEffect(() => {
    caricaUtenti(query);
  }, [query]);


  function PageSize(pagesize: number) {
    dispatch({ type: azioni.PageSize, value: pagesize });
  }
  function PageNumber(pageNumber: number) {
    dispatch({ type: azioni.GoToPage, value: pageNumber });
  }

  function SearchByName(input: string) {
    dispatch({ type: azioni.Search, value: { searchBy: 'UserName', searchString: input } });
  }

  function SortByProperty(property: string, direction: 'asc' | 'desc') {
    dispatch({ type: azioni.SortBy, value: { sortBy: property, direction: direction } });
  }

  //------------dialogs/toast
  function openUpdate(userId: string) {
    let user: Utente = listaUtenti.filter(u => u.id === userId)[0];
    setutenteSelez(user);
    setDialog(Dialogs.Create);
  }

  function openDelete(userId: string) {
    let user: Utente = listaUtenti.filter(u => u.id === userId)[0];
    setutenteSelez(user);
    setDialog(Dialogs.Delete);
  }

  async function handleDelete() {
    setLoadingDialog(true);
    let ok = await GestioneUtenteService.Delete(utenteSelez.id);

    setToast({ show: true, success: ok })

    setLoadingDialog(false);
    setDialog(Dialogs.None);
    caricaUtenti(query);
  }

  function scrollToTop(){
   document.getElementById('paginagestutenti')?.scrollTo(0,0);
  }

  return <>
    <AdminRestrictedPage>

      <GenericEntityPage //PAGINA
        
        tipo={tableObjects.Utente}
        titolo={Dictionary.GestioneUtenti.gestioneUtenti_sectionTitle}
        oggetti={listaUtenti}
        ricaricaOggetti={() => { }}
        loading={searching}
        ricerca={SearchByName}
        onAdd={() => { setutenteSelez(new Utente()); setDialog(Dialogs.Create) }}
        onSort={SortByProperty}
        onUpdate={openUpdate}
        onDelete={openDelete}
        sortBy={query.sortBy}
        sortDirection={query.sortDirection}
        queryModel={query}
        changePageSize={PageSize}
        goToPage={PageNumber}
        count={count}
        currentPage={current}
        tipoElementi= {"Utenti"}
        config= {new Config()}
        />
      
    </AdminRestrictedPage>

    <FormDialogUtenti utente={utenteSelez} //DIALOG CREA UTENTE
      errorFunction={() => {caricaUtenti(query) }}
      notvalidFunction={() => { }}
      confirmFunction={() => { caricaUtenti(query) }}
      _open={dialog === Dialogs.Create}
      mostraModal={() => { setDialog(Dialogs.None) }}
      />

    <GenericAlert //DIALOG ELIMINAZIONE
      _open={dialog === Dialogs.Delete}
      _titolo={"ELIMINA UTENTE"}
      messaggioConferma={uitext.confermaElimina + utenteSelez.userName}
      _nomeSala={''}
      confirmFunction={handleDelete}
      mostraModal={() => { setDialog(Dialogs.None) }}
      loading={loadingDialog}
    />

    <GenericToast //TOAST CONFERMA DELETE
      _open={toast.show} _vertical='bottom' _horizontal="right"
      _severity={toast.success ? 'success' : 'error'}
      _message={toast.success ? Dictionary.GestioneUtenti.utente_eliminato : Dictionary.GestioneUtenti.utente_non_eliminato}
      mostraModal={() => { setToast({ ...toast, show: false }) }}
      _autoClose={3000} />

  </>

}

export default GestioneUtenti;
