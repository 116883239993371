import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faGear, faPager, faTrash, faCircleXmark, faCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingBar from '../../Layouts/LoadingBar';
import EnhancedTableHead, { SortOrder } from './GenericTableHeader';
import { tableObjects } from '../../Services/Enums/ObjectsEnum';
import { Utente } from '../../Models/Utente';
import { Sala } from '../../Models/Sala';
import { Pannello } from '../../Models/Pannello';
import { QueryModel } from '../../Services/Utils/QueryModel';
import "./genTablePage.css";
import Dictionary from '../../Config/dictionary';
import { ButtonGroup, Tooltip } from '@mui/material';
import { Config } from '../../Models/Config';
import { SignageBook } from '../../Models/SignageBook';
import {useNavigate} from "react-router-dom"
import { Card } from '../../Models/Card';


const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    },
  }
});

type Props = {
  tipo: tableObjects;
  oggetti: object[];
  ricaricaElementi: Function;
  loading: boolean;

  onUpdate?: Function;
  onDelete?: Function;
  onSort?: Function;

  sortBy?: string;
  sortDirection?: SortOrder;
  queryModel: QueryModel;
  changePageSize: Function;
  goToPage: Function;
  count: number;
  currentPage: number;
  tipoElementi: string;
  config: Config;
};

//elenco nome attributi che non diventeranno celle visualizzate in tabella
enum noShow {
  id = 'id',
  noDelete = 'noDelete',
  noDeleteMsg = 'noDeleteMsg',
}

function ShowAttribute(value: noShow) {
  return !Object.values(noShow).includes(value);
}

const GenericTable: React.FC<Props> = ({ currentPage, count, tipo, oggetti, loading, onUpdate, onDelete, onSort, sortBy, sortDirection, queryModel, changePageSize, goToPage, tipoElementi, config }) => {

  const navigate = useNavigate();

  const classes = useStyles();

  function updateHandler(id: string | number) {
    onUpdate && onUpdate(id);
  }
  function signageBookHandler(id: string) {
    sessionStorage.setItem("idSala", id);
    navigate("/signage");
  }
  function deleteHandler(id: string | number) {
    onDelete && onDelete(id);
  }

  function sortHandler(sortBy: string, direction: 'asc' | 'desc') {
    console.log('SORTING')
    onSort && onSort(sortBy, direction);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    goToPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePageSize(parseInt(event.target.value));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 1 }}>
        <LoadingBar show={loading} />

        <TableContainer>
          <Table
            className='Grid_Sale'
            size="small"
            aria-colcount={12}
          >
            <EnhancedTableHead
              tipo={tipo}
              oggetti={oggetti}
              sortBy={sortBy || ''}
              sortDirection={sortDirection || 'asc'}
              onSort={sortHandler}
            />

            <TableBody>
              {compileRows(tipo, oggetti).map((row: any) =>

                <TableRow tabIndex={-1}
                  key={'row_key_' + row.id}
                  className="row_table" >

                  {Object.keys(row).map(key => <>

                    {ShowAttribute(key as noShow) && //controlla campi non visualizzabili  
                      <TableCell
                        key={"tableCellKey" + row.id + key}
                        align={"left"}
                        className="nameCell"
                        id={'tablecellId_' + key}
                        scope="row"
                      >{row[key]}</TableCell>}</>)}

                  <TableCell  //CELLA AZIONI MODIFICA/ELIMINA 
                    className="actionsCell" align="right">
                      
                    {(tipoElementi==="Sala"&& config.signageBook == true) && 
                  <IconButton
                      className={classes.root + 'act_cell'}
                      disableRipple={true}
                      onClick={() => { signageBookHandler(row.id); }} >
                      <FontAwesomeIcon color="var(--coloreSecondario)"
                        icon={faPager} className="panelIcon" />
                    </IconButton>}
                    {tipoElementi!=="Signage"&&
                    <IconButton
                      className={classes.root + 'act_cell'}
                      disableRipple={true}
                      onClick={() => { updateHandler(row.id); }} >
                      <FontAwesomeIcon color="var(--coloreSecondario)"
                        icon={faGear} className="gearIcon" />
                    </IconButton>}

                    {row.noDelete ?
                      <Tooltip placement='left'
                        disableFocusListener disableTouchListener
                        enterDelay={700}
                        enterNextDelay={700}
                        className='tooltip-padding'
                        title={row.noDeleteMsg || 'Funzionalità Disattivata'}>
                        <IconButton
                          disableRipple={true}
                          className={classes.root}
                          onClick={() => { }}>
                          <FontAwesomeIcon color={"lightgrey"}
                            icon={faBan} className="trashIcon" />
                        </IconButton>
                      </Tooltip>
                      :
                      <IconButton
                        disableRipple={true}
                        className={classes.root}
                        onClick={() => { deleteHandler(row.id) }}>
                        <FontAwesomeIcon color={"var(--coloreSecondario)"}
                          icon={faTrash} className="trashIcon" />
                      </IconButton>}

                  </TableCell>
                </TableRow>
              )}

            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination //CONTROLLI PAGINAZIONE
          labelRowsPerPage={Dictionary.Pagination.pagination_label}

          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={queryModel.pageSize}
          page={currentPage > 0 ? currentPage - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Paper >
    </Box >
  );

}
export default GenericTable;


//crea oggetti per display in tabella a seconda del tipo indicato
function compileRows(objectType: tableObjects, oggetti: any[]) {

  let now = new Date();
  let rows: any[] = [];
  let rowMaker: Function = (object: any) => { };

  switch (objectType) {
    case tableObjects.Utente: {
      rowMaker = (utente: Utente) => {
        return {
          id: utente.id,
          UserName: utente.userName,
          Ruolo: utente.role,
          noDelete: utente.isLastAdmin,
          noDeleteMsg: Dictionary.GestioneUtenti.non_elim_ultimo_admin
        }
      }
      break;
    }
    case tableObjects.Sala: {
      rowMaker = (sala: Sala) => { return { id: sala.idSala, nome: sala.nome, descrizione: sala.descrizione, /* stato: sala.stato, */ capienza: sala.capienza, } }

      break;
    }
    case tableObjects.Pannello: {
      rowMaker = (pannello: Pannello) => {
        return { id: pannello.idPannello, nome: pannello.nome, sala: pannello.nomeSala, statoPannello: pannello.statoPannello? <><FontAwesomeIcon color={"var(--coloreOkStatus)"}
        icon={faCircle} className="trashIcon" /> <span>Attivo</span></> : <><FontAwesomeIcon color={"var(--coloreWarning)"}
        icon={faCircleXmark} className="trashIcon" /> <span>Non attivo</span></> } }
      break;
    }
    case tableObjects.Signage: {
      rowMaker = (signage: SignageBook) => { return { id: signage.idSignageBook , nomeFile: signage.nomeFile, file: <img className='spot' src={"data:image/png;base64," + signage.file}></img>/* (signage.file as unknown as string).substring(370,440) */ } }
      break;
    }
    case tableObjects.Card: {
      rowMaker = (card: Card) => { return { id: card.id , nome: card.user, codice: card.identifier }}
      break;
    }
  }

  for (let oggetto of oggetti) {
    rows.push(rowMaker(oggetto));
  }

  return rows;
}


/*  campi visualizzabili solo mobile
                      <p className='capienza_mobile'><b>UserName:</b> {row.userName}</p>
                    <p className='stato_mobile'><b>Ruolo:</b> {row.role}</p> */

