import { stepLabelClasses } from "@mui/material";
import axios from "axios";
import { Endpoints } from "../Config/Endpoints";
import { SignageBook } from "../Models/SignageBook";
import { SessionManager } from "./SessionManager";
import { RequestConfig } from "./Utils/RequestConfig";

export default class SignageService {


    public static async GetPanelSignage(idSala: number): Promise<SignageBook[]> {

        let signage: Array<SignageBook> = [];
        await axios.post(Endpoints.SignageBook.post_Get_Panel_Signage + idSala)

            .then((response: any) => {

                console.log(response.data)
                signage = response.data;
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return signage;
    }


    public static RemoveSignage = async function (id: number | undefined) {

        let success: boolean = false;

        await axios.delete(Endpoints.SignageBook.delete + id, RequestConfig.deletedata())
            .then((response) => {
                console.log(response, "Signage eliminata");
                success = true;
            }, (error) => {
                console.log(error, "Signage non eliminata");
            });
        SessionManager.RefreshSession();
        return success;
    }

    public static AddSignage = async function (signage: SignageBook) {

        return await axios.post(Endpoints.SignageBook.post_Add, signage, RequestConfig.queryparams())
            .then((response) => {
                console.log(response, "aggiunta effettuata ");
                console.log(response.data.id)
                SessionManager.RefreshSession();
                return true;
            }, (error) => {

                console.log(error, "chiamata effettuata ma nessuna signage aggiunta");
                return false;
            });
    }

}

export class pagedSignage {
    count: number = 0;
    currentPage: number = 0;
    lista: SignageBook[] = [];
}