
import { useNavigate } from "react-router-dom";
import Dictionary from "../../Config/dictionary";

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <div style={{marginTop:'4rem',}}>Non abbiamo trovato la pagina che stai cercando</div>
            <div style={{ color: 'green', cursor:'pointer' }}
                onClick={() => { navigate(Dictionary.Routes.dashboard_route, { replace: true }); }}>
                Torna alla Home</div>
        </>
    )
}

export default NotFound;