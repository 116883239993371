import { Button, Paper, TextField, Typography } from '@mui/material';
import { Utente } from '../../Models/Utente';
import { Expressions } from '../../Services/Enums/Expressions';
import { UserRoles } from '../../Services/Enums/UserRolesEnum';
import { ReducerAction } from "../../Services/Reducers/ReducerAction";
import './ProfiloUtente.css';
import { useReducer, useState } from 'react';
import GestioneUtenteService, { PasswordChangeModel } from '../../Services/GestioneUtenteService';
import { ToastSet } from './ProfiloUtente';
import { SessionManager } from '../../Services/SessionManager';
import { useSession } from '../../Context/SessionContext';
import LoadingBar from '../../Layouts/LoadingBar';

class statoForm {
    utenteForm: Utente = new Utente();
    current_pass: string = '';
    new_pass: string = '';

    currentValid: boolean = false;
    newPassValid: boolean = false;
    passConfirm: boolean = false;

    inputChanged: boolean = false;
    submitted: boolean = false;

    constructor(utente: Utente) {
        this.utenteForm = { ...utente };
        this.utenteForm.role = this.utenteForm.id ? this.utenteForm.role : UserRoles.USER;
    }
}

type FormUtenteProp = {
    utente: Utente,
    onCancel?: Function,
    onConfirm?: Function,
}

enum azioniProfilo {
    CHANGE_INPUT = 'changeinput',
    SUBMIT_ATTEMPT = 'submitattempt'
}

enum inputPassword {
    CURRENT_PASS = 'inputpassword_username',
    NEW_PASS = 'inputpassword_email',
    CONF_PASS = 'inputpassword_password'
}

function ModificaProfiloReducer(state: statoForm, action: ReducerAction) {
    let stato = { ...state }

    switch (action.type) {
        case azioniProfilo.CHANGE_INPUT: {
            stato = compilaForm(stato);
            stato.inputChanged = true;
            break;
        }
        case azioniProfilo.SUBMIT_ATTEMPT: {
            stato.submitted = true;
            break;
        }
    }

    return stato;
}

function compilaForm(state: statoForm): statoForm {

    state.current_pass = (document.getElementById(inputPassword.CURRENT_PASS) as HTMLInputElement).value.trim();
    state.new_pass = (document.getElementById(inputPassword.NEW_PASS) as HTMLInputElement).value.trim();

    state.currentValid = state.current_pass.length > 0;
    state.newPassValid = state.new_pass.length > 8;
    state.passConfirm = state.new_pass === (document.getElementById(inputPassword.CONF_PASS) as HTMLInputElement).value.trim();

    return state;
}

function noSpace(e: React.KeyboardEvent) {
    let key = e.key;
    if (key === ' ') e.preventDefault();
}

const FormModificaProfilo: React.FC<FormUtenteProp> = ({ utente, onCancel, onConfirm }) => {
    const [state, dispatch] = useReducer(ModificaProfiloReducer, new statoForm(utente));
    const { session, setSession } = useSession();
    const [loading, setLoading] = useState(false);

    function handleCancel() {
        if (onCancel) onCancel();
    }

    async function handleSubmit() {
        /*
                if (!state.inputChanged) {
                    handleCancel();
                    return;
                }
        */

        if (FormIsValid()) {
            setLoading(true);

            let model = new PasswordChangeModel(state.utenteForm.id, state.current_pass, state.new_pass);

            let t = new ToastSet();
            t.show = true;
            t.success = await GestioneUtenteService.passwordChange(model);

            setLoading(false);
            onConfirm && onConfirm(t);

        } else { dispatch({ type: azioniProfilo.SUBMIT_ATTEMPT }) }

    }

    function FormIsValid(): boolean {
        if (state.currentValid &&
            state.newPassValid &&
            state.passConfirm
        ) return true;
        return false;
    }

    function handleChange() {
        dispatch({ type: azioniProfilo.CHANGE_INPUT })
    }

    return <>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <LoadingBar show={loading} />
            <Paper className="card-profilo" elevation={3}>

                <TextField //CURRENT PASSWORD 
                    id={inputPassword.CURRENT_PASS}
                    type='password' className="inputAccount"
                    color='success' margin="dense" variant="standard"
                    error={state.submitted && !state.currentValid}
                    label="Password Attuale"
                    onKeyDown={(e) => { noSpace(e) }}
                    onChange={handleChange}
                /><br />

                <TextField //NEW PASSWORD  
                    id={inputPassword.NEW_PASS}
                    type='password' className="inputAccount"
                    color='success' margin="dense" variant="standard"
                    error={state.submitted && !state.newPassValid}
                    label="Nuova Password"
                    onChange={handleChange}
                /><br />


                <TextField //CONFIRM NEW PASSWORD  
                    id={inputPassword.CONF_PASS}
                    type='password' className="inputAccount"
                    color='success' margin="dense" variant="standard"
                    error={state.submitted && !state.passConfirm}
                    label="Conferma Nuova Password"
                    onChange={handleChange}
                /><br />


            </Paper>

            <Button className='btnpad' color="success"
                onClick={handleCancel}>
                Annulla
            </Button>
            <Button className='btnpad' variant="contained"
                color="success" type='submit'>
                Salva
            </Button>
        </form>
    </>


}

export default FormModificaProfilo;




