import { red } from "@mui/material/colors";
import axios from "axios";
import { Endpoints } from "../Config/Endpoints";
import { Card } from "../Models/Card";
import { SessionManager } from "./SessionManager";
import { QueryModel } from "./Utils/QueryModel";
import { RequestConfig } from "./Utils/RequestConfig";

export default class CardService {

    public static async getByIdentifier(identifier:string): Promise<Card> {

        return await axios.get(Endpoints.Card.get, RequestConfig.queryparams({identifier}))
            .then((res) => {
                SessionManager.RefreshSession();
                console.log(res.data);
                return {
                    id: +res.data?.id || 0,
                    user: res.data?.user || '',
                    identifier: res.data?.identifier || ''
                };

            }, (error: any) => {
                console.log(error);
                SessionManager.RefreshSession();
                return new Card();
            });

    }

    public static async GetPaginated(model: QueryModel): Promise<pagedCard> {

        let pagedlist = new pagedCard();

        return await axios.post(Endpoints.Card.get_Paginated, model, RequestConfig.queryparams({}))
            .then((res) => {

                pagedlist.count = parseInt(res.headers.totalcount);
                pagedlist.currentPage = parseInt(res.headers.currentpage);

                for (let obj of res.data) {
                    pagedlist.lista.push({
                        id: obj.id,
                        user: obj.user,
                        identifier: obj.identifier
                    });
                }

                SessionManager.RefreshSession();
                return pagedlist;

            }, (error: any) => {
                return pagedlist;
            });

    }

    public static Add = async function (card: Card) {

        return await axios.post(Endpoints.Card.post_Add, card, RequestConfig.queryparams())
            .then((response) => {
                console.log(response)
                SessionManager.RefreshSession();
                return true;

            }, (error) => {
                console.log(error, "creazione card non riuscita");
                return false;
            });
    }

    public static Delete = async function (id: number): Promise<boolean> {

        return await axios.delete(Endpoints.Card.delete + id, RequestConfig.deletedata())
            .then((response) => {
                SessionManager.RefreshSession();
                return true;
            }, (error) => {
                console.log(error, "eliminazione non riuscita");
                return false;
            });

    }



    public static Update = async function (card: Card): Promise<boolean> {

        return await axios.put(Endpoints.Card.put_Update, card, RequestConfig.queryparams())
            .then((response) => {
                SessionManager.RefreshSession();
                return true;
            }, (error) => {
                console.log(error, "modifica non riuscita");
                return false;
            });
    }
}

export class pagedCard {
    count: number = 0;
    currentPage: number = 0;
    lista: Card[] = [];
}