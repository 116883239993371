import { useSession } from "../Context/SessionContext";
import UnAuthorized from "../Pages/Navigazione/UnAuthorized";
import { SessionManager } from "../Services/SessionManager";


export const AdminRestrictedPage: React.FC = ({ children }) => {
    const { session, setSession } = useSession();

    return <>
        {SessionManager.LoggedAsAdmin(session) ? children : <UnAuthorized />}
    </>
}


export const AdminOnlyContent: React.FC = ({ children }) => {
    const { session, setSession } = useSession();

    return <>
        {SessionManager.LoggedAsAdmin(session) ? children : ''}
    </>
}



