export class ImpostazioniGenerali {
    idImpostazioniGenerali?: number;
    instantBooking?: boolean;
    passo?: number;
    checkInTimer?: number;
    configInstantBooking: boolean | undefined;
    checkIn?: boolean;
    configCheckIn: boolean | undefined;


    constructor(idImpostazioniGenerali?: number, instantBooking?: boolean, configInstantBooking?: boolean | undefined, passo?: number, checkInTimer?: number,
        checkIn?: boolean, configCheckIn?: boolean | undefined) {

        this.idImpostazioniGenerali = idImpostazioniGenerali;
        this.instantBooking = instantBooking;
        this.passo = passo;
        this.checkInTimer = checkInTimer;
        this.configInstantBooking = configInstantBooking;
        this.checkIn = checkIn;
        this.configCheckIn = configCheckIn;
    }

    public static costruttoreEsteso(
        idImpostazioniGenerali: number,
        instantBooking: boolean,
        passo: number,
        checkInTimer: number,
        configInstantBooking: boolean,
        checkIn: boolean,
        configCheckIn: boolean) {

        let impostazioni = new ImpostazioniGenerali();

        if (idImpostazioniGenerali) impostazioni.idImpostazioniGenerali = idImpostazioniGenerali;
        if (instantBooking) impostazioni.instantBooking = instantBooking;
        if (passo) impostazioni.passo = passo;
        if (checkInTimer) impostazioni.checkInTimer = checkInTimer;
        if (configInstantBooking) impostazioni.configInstantBooking = configInstantBooking;
        if (checkIn) impostazioni.checkIn = checkIn;
        if (configCheckIn) impostazioni.configCheckIn = configCheckIn;

        return impostazioni;
    }

}